<template>
  <v-container fluid>
    <v-row>
      <v-col
          cols="12"
      >
        <v-tabs grow>
          <v-tab>События</v-tab>
          <v-tab>Маршруты</v-tab>
          <v-tab>Достопримечательности</v-tab>
          <v-tab>Отели</v-tab>
          <v-tab>Рестораны</v-tab>
          <v-tab-item>
            <places-table :places="events" place-type="event" @loadPlaces="load"></places-table>
          </v-tab-item>
          <v-tab-item>
            <places-table :places="routes" place-type="route" @loadPlaces="load"></places-table>
          </v-tab-item>
          <v-tab-item>
            <places-table :places="attractions" place-type="attraction" @loadPlaces="load"></places-table>
          </v-tab-item>
          <v-tab-item>
            <places-table :places="hotels" place-type="hotel" @loadPlaces="load"></places-table>
          </v-tab-item>
          <v-tab-item>
            <places-table :places="restaurants" place-type="restaurant" @loadPlaces="load"></places-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import PlacesTable from "@/components/PlacesTable";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Places",
  components: {
    PlacesTable
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      attractions: 'places/attractions',
      hotels: 'places/hotels',
      restaurants: 'places/restaurants',
      routes: 'places/routes',
      events: 'places/events'
    })
  },
  methods: {
    ...mapActions({
      loadCities: 'places/loadCities',
      loadPlaces: 'places/loadPlaces'
    }),
    load({placeType, onLoad}) {
      this.loadPlaces(placeType).finally(() => onLoad())
    }
  },
  mounted() {
    this.loadCities()
  }

}
</script>

<style scoped>

</style>
