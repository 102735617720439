<template>
  <v-card>
    <v-card-actions>
      <v-btn @click="loadPlaces">Обновить</v-btn>
      <v-btn color="warning" @click="loadPictureInfo">Изображения</v-btn>
      <v-overlay :value="overlay">
        <v-progress-circular :indeterminate="loadingTotal<=0"
                             :value="100*loadingCurrent/loadingTotal">

        </v-progress-circular>
      </v-overlay>

      <v-spacer></v-spacer>
      <v-btn @click="createMode">Создать</v-btn>

    </v-card-actions>
    <div v-show="isDebug">
      <v-card-actions>
        <attraction-loader v-show="placeType==='attraction'" @done="refresh"></attraction-loader>
        <hotel-loader v-show="placeType==='hotel'" @done="refresh"></hotel-loader>
        <restaurant-loader v-show="placeType==='restaurant'" @done="refresh"></restaurant-loader>
        <event-loader v-show="placeType==='event'" @done="refresh"></event-loader>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteAll">Удалить всё</v-btn>
      </v-card-actions>
    </div>
    <v-card-text>
      <v-data-table
          ref="table"
          :footer-props="{
        itemsPerPageOptions:[
            10, 25,50, 100, 250, -1
        ]
          }"
          :headers="combinedHeaders"
          :items="places"
          :loading="loading"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          loading-text="Загрузка..."
          @click:row="editMode"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              class="mx-4"
              label="Поиск"
              clearable
          ></v-text-field>
        </template>
        <template v-slot:item.cityId="{ item }">
          {{ getCity(item.cityId) }}
        </template>
        <template v-slot:item.cover="{ item }">
          <file-storage-image
              :key="refreshKey + item.id"
              :height="50"
              :path="item.path"
              file-name="x/1.jpg"
              style="width: 50px"
              type="Picture"
          ></file-storage-image>
        </template>
        <template v-slot:item.visible="{ item }">
          <div v-if="item.visible">
            <v-btn
                class="mx-2"
                icon
                outlined
                @click.stop="publishPlace(item, false)"
            >
              <v-icon>mdi-eye-off</v-icon>
            </v-btn>
            <v-chip class="green lighten2">Опубликован</v-chip>
          </div>
          <div v-else>
            <v-btn
                class="mx-2"
                icon
                outlined
                @click.stop="publishPlace(item, true)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-chip class="grey darken-1">Не опубликован</v-chip>
          </div>
        </template>
        <template v-slot:item.image="{ item }">
          <v-icon :color="!!item.image ? 'success': 'error'">
            {{ item.image == null ? '' : item.image ? 'mdi-checkbox-marked-circle' : 'mdi-close' }}
          </v-icon>
        </template>
        <template v-slot:item.districtId="{ item }">
          {{ getDistrict(item.districtId) }}
        </template>
      </v-data-table>
    </v-card-text>
    <place-card
        v-model="dialog"
        :create=createPlaceMode
        :place="selectedPlace"
        :place-type="placeType"
        @open="open"
        @refresh="refresh"></place-card>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {formatDateFromMs} from "@/plugins/formatters";
import PlaceCard from "@/components/PlaceCard";
import AttractionLoader from "@/components/debug/AttractionLoader";
import HotelLoader from "@/components/debug/HotelLoader";
import RestaurantLoader from "@/components/debug/RestaurantLoader";
import EventLoader from "@/components/debug/EventLoader";
import FileStorageImage from "@/components/widgets/FileStorageImage";

export default {
  name: "PlacesTable",
  components: {FileStorageImage, EventLoader, RestaurantLoader, HotelLoader, AttractionLoader, PlaceCard},
  data() {
    return {
      selectedPlace: {},
      createPlaceMode: false,
      sortBy: 'cityId',
      sortDesc: true,
      headers: [
        {text: 'id', value: 'id'},
        {text: 'pic', value: 'image'},
        {text: 'cover', value: 'cover'},
        {text: 'Имя', value: 'name'},
        {text: 'Вес', value: 'weight'},
        {text: 'Рейтинг', value: 'rating'},
        {text: 'Район', value: 'districtId'},
        {text: 'Город', value: 'cityId'},
        {text: 'Адрес', value: 'address'},
        {text: 'Путь', value: 'path'},
        {text: 'Visible', value: 'visible'},
      ],
      customHeaders: {
        attraction: [{text: "Длительность", value: "duration"}, {text: "Широта", value: "latitude"},
          {text: "Долгота", value: "longitude"},],
        route: [{text: "Создатель", value: "ownerId"}],
        hotel: [
          {text: "Широта", value: "latitude"},
          {text: "Долгота", value: "longitude"},
        ],
        restaurant: [{text: "Широта", value: "latitude"},
          {text: "Долгота", value: "longitude"},],
        event: [
          {text: 'Начало', value: 'startDate'},
          {text: 'Конец', value: 'endDate'},
          {text: "Широта", value: "latitude"},
          {text: "Долгота", value: "longitude"},
        ],
      },
      loading: true,
      dialog: false,
      selectedId: 0,
      search: null,
      overlay: false,
      loadingTotal: 0,
      loadingCurrent: 0,
      refreshKey: ""
    }
  },
  computed: {
    ...mapGetters({
      tags: 'places/tags',
      isSuperUser: 'api/isSuperUser'
    }),
    isDebug() {
      return this.isSuperUser || process.env.NODE_ENV === 'development';
    },
    combinedHeaders() {
      return [...this.headers, ...this.customHeaders[this.placeType]];
    }
  },
  methods: {
    ...mapActions({
      deletePlace: 'places/deletePlace',
      getUsers: 'users/getUsers',
      loadTags: 'places/loadTags',
      fetchFiles: 'files/fetchFiles',
      setVisible: 'places/setVisible'
    }),
    getCity(id) {
      const city = this.tags('Cities').find(x => x.id === id);
      return city?.name ?? id;
    },
    getDistrict(id) {
      const district = this.tags('District').find(x => x.id === id);
      return district?.name ?? id;
    },
    formatDay(obj1) {
      return formatDateFromMs(obj1);
    },
    loadPlaces() {
      console.log(this.$refs.table);

      this.loading = true;
      const app = this;
      console.log(this.$props)
      this.$emit('loadPlaces', {
        placeType: this.$props.placeType,
        onLoad: () => {
          app.loading = false;
        }
      })

      this.refreshKey = Date.now()
    },
    refresh() {
      this.loadPlaces();
    },
    createMode() {
      this.createPlaceMode = true;
      this.selectedPlace = {id: 0}
      this.dialog = true
    },
    editMode(place) {
      this.createPlaceMode = false;
      this.selectedPlace = place
      this.dialog = true
    },
    open(id) {
      if (!id || id === '0')
        return;
      const place = this.places.find(x => x.id.toString() === id.toString());

      console.log(`Open by id ${id}`, place);

      if (place)
        this.editMode(place)
    },
    async deleteAll() {
      for (const place of this.places) {
        console.log(place)
        await this.deletePlace({placeType: this.placeType, placeId: place.id})
      }
      this.refresh()
    },
    async loadPictureInfo() {
      const result = confirm("Подгрузится информация о том, у каких мест нет обложек.\nЭто трудоёмкий процесс.\nНачать?");

      if (!result) return;
      this.overlay = true;
      this.loadingTotal = 0;

      const fileInfos = (await this.fetchFiles({type: 'Picture', path: ''})).data.map(x => x.fileName);
      this.loadingCurrent = 0;
      this.loadingTotal = this.places.length;
      console.log(fileInfos)

      let missing = [];

      console.log("Places count", this.places.length);
      for (const i in this.places) {
        const place = this.places[i];
        console.log(place.path);
        this.places[i].image = (fileInfos.includes(`${place.path}/x/1.jpg`)
            && fileInfos.includes(`${place.path}/2x/1.jpg`))

        if (!this.places[i].image) missing.push({path: place.path, id: place.id, name: place.name});
        this.loadingCurrent++;
      }

      this.overlay = false;
      this.$refs.table.$forceUpdate()
      console.log(JSON.stringify(missing));
    },
    async publishPlace(item, mode) {
      console.log("SET VISIBLE", item, mode)
      await this.setVisible({
        placeType: this.placeType,
        placeId: item.id,
        mode
      });
      this.refresh();
    }
  },
  async mounted() {
    await this.loadTags('Cities')
    await this.loadTags('Subject');
    await this.loadTags('PlaceType');
    await this.loadTags('District');
    await this.loadTags('peopleType');
    await this.loadTags('subjectName');
    await this.loadTags('subjectType');
    await this.loadTags('ageType');
    await this.loadTags('placeType');
    await this.loadTags('equipmentType');
    await this.loadTags('serviceType');
    await this.loadTags('cateringType');
    await this.loadTags('housingType');
    await this.loadTags('denyType');
    await this.loadTags('cuisineType');
    this.loadPlaces()
  },

  props: {
    places: {type: Array, required: true, default: () => []},
    placeType: {type: String, required: true}
  }
}
</script>

<style scoped>

</style>
