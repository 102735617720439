<template>
  <v-container fluid>
    <v-row>
      <v-col
          cols="12"
      >
        <v-card id="auth" class="mx-auto my-12" flat width="600">
          <v-card-title>Создание нового пароля</v-card-title>
          <v-form v-if="!canLogin"
                  ref="form"
                  v-model="valid"
                  @submit.prevent="resetPassword"
          >
            <input id="username" name="fakeusernameremembered" style="display:none" type="text">
            <input id="password" name="fakepasswordremembered" style="display:none" type="password">
            <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                label="Пароль"
                required
                @click:append="showPassword = !showPassword"
            >
            </v-text-field>
            <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[...rules, matchPassword]"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                label="Повторите пароль"
                required
                @click:append="showPassword = !showPassword"
            >
            </v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" type="submit" @click="resetPassword">Подтвердить</v-btn>
            </v-card-actions>
          </v-form>
          <div v-else>
            <v-card-text>
              Пароль успешно изменён, перейдите в приложение и авторизуйтесь с новым паролем
            </v-card-text>
<!--            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="$router.push('/')">Войти</v-btn>
            </v-card-actions>-->
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Reset",
  data() {
    return {
      min: 6,
      showPassword: false,
      resultText: "Отправляем",
      canLogin: false,
      valid: false,
      password: ""
    }
  },
  methods: {
    ...mapActions({
      resetPasswordWithStamp: 'api/resetPasswordWithStamp'
    }),
    resetPassword() {
      this.resetPasswordWithStamp({
        userId: this.$route.query.userId,
        signature: this.$route.query.signature,
        password: this.password
      })
          .then(() => {
            this.canLogin = true;
          })
          .catch(() => this.resultText = "Ошибка")
    }
  },
  mounted() {

  },
  computed: {
    rules() {
      const rules = []
      {
        const rule =
            v => (v || '').length >= this.min ||
                `Минимальное количество символов: ${this.min}`
        rules.push(rule)
      }
      return rules
    },
    matchPassword() {
      return v => v === this.password || "Пароли не совпадают"
    }
  }
}
</script>

<style scoped>

</style>
