import axios from "@/plugins/axios";

export const api = {
    namespaced: true,
    state: () => ({
        token: null,
        status: '',
        user: {},
        requestInterceptorId: -1,
        superUsers:[
            "oklikhunter@mail.ru"
        ],
        accountManagers:["ok@novgorod.travel"]
    }),
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        isLoading: state => state.status === 'loading',
        user: state => state.user,
        isSuperUser: state => state.superUsers.includes(state.user.email),
        canCreateAdmins: state => state.superUsers.includes(state.user.email)
            || state.accountManagers.includes(state.user.email),
    },
    mutations: {
        api_request(state) {
            state.status = 'loading'
        },
        api_success(state) {
            state.status = 'success'
        },
        api_error(state) {
            state.status = 'error'
        },
        auth_success(state, token) {
            console.log(token)
            state.status = 'success'
            state.token = token
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            localStorage.removeItem('token')
            axios.defaults.headers.common.Authorization = null
        },
        set_user(state, user) {
            state.user = user
        },
        setToken(state, token) {
            console.log("setting token", token);
            if (token) {
                state.token = token;
                localStorage.setItem('token', token)
                axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
                axios.interceptors.request.eject(state.requestInterceptorId)
                state.requestInterceptorId = axios.interceptors.request.use(request => {
                    if (!request.data) request.data = {}
                    //request.data.access_token = state.token;
                    return request;
                })
            }
        },
        loadToken(state) {
            if (!state.token) {
                state.token = localStorage.getItem('token') || '';
            }
        },

    },
    actions: {
        initToken({commit, state}) {
            return new Promise((resolve) => {
                commit('loadToken');
                commit('setToken', state.token);
                resolve();
            })
        },
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('api_request');
                axios.post('Account/Login', user)
                    .then(resp => {
                        console.log(resp.data)
                        const token = resp.data
                        console.log(token)
                        commit('setToken', token);
                        commit('auth_success', token)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        register({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('api_request');
                axios.post('Account/Register', user)
                    .then(resp => {
                        console.log(resp.data)
                        commit('api_success')
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('api_error')
                        reject(err)
                    })
            })
        },
        recover(_, email) {
            return new Promise((resolve, reject) => {
                axios.post(`Account/RecoverPassword`, {
                    email: email
                }) //who knows
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getUserInfo({commit, state}) {
            return new Promise((resolve, reject) => {
                if (!state.token) {
                    resolve();
                    return;
                }
                commit('api_request');
                axios.get(`Account/GetSelfInfo`)
                    .then(resp => {
                        commit('set_user', resp.data)
                        commit('api_success')
                        console.log(resp)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('api_error')
                        console.log(err)
                        reject(err)
                    })
            })
        },
        confirmEmail(_, {userId, signature}) {
            return new Promise((resolve, reject) => {
                axios.get(`Account/ConfirmEmail?user=${userId}&signature=${signature}`)
                    .then(resp => {
                        console.log(resp)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        resetPasswordWithStamp(_, {userId, signature, password}) {
            return new Promise((resolve, reject) => {
                axios.post(`Account/ResetPasswordWithStamp`,
                    {
                        userId: userId,
                        signature: signature,
                        password: password
                    })
                    .then(resp => {
                        console.log(resp)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        }
    }
}
