var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.loadPlaces}},[_vm._v("Обновить")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.loadPictureInfo}},[_vm._v("Изображения")]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":_vm.loadingTotal<=0,"value":100*_vm.loadingCurrent/_vm.loadingTotal}})],1),_c('v-spacer'),_c('v-btn',{on:{"click":_vm.createMode}},[_vm._v("Создать")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDebug),expression:"isDebug"}]},[_c('v-card-actions',[_c('attraction-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeType==='attraction'),expression:"placeType==='attraction'"}],on:{"done":_vm.refresh}}),_c('hotel-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeType==='hotel'),expression:"placeType==='hotel'"}],on:{"done":_vm.refresh}}),_c('restaurant-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeType==='restaurant'),expression:"placeType==='restaurant'"}],on:{"done":_vm.refresh}}),_c('event-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeType==='event'),expression:"placeType==='event'"}],on:{"done":_vm.refresh}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteAll}},[_vm._v("Удалить всё")])],1)],1),_c('v-card-text',[_c('v-data-table',{ref:"table",attrs:{"footer-props":{
      itemsPerPageOptions:[
          10, 25,50, 100, 250, -1
      ]
        },"headers":_vm.combinedHeaders,"items":_vm.places,"loading":_vm.loading,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":"Загрузка..."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.editMode},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Поиск","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.cityId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCity(item.cityId))+" ")]}},{key:"item.cover",fn:function(ref){
        var item = ref.item;
return [_c('file-storage-image',{key:_vm.refreshKey + item.id,staticStyle:{"width":"50px"},attrs:{"height":50,"path":item.path,"file-name":"x/1.jpg","type":"Picture"}})]}},{key:"item.visible",fn:function(ref){
        var item = ref.item;
return [(item.visible)?_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.publishPlace(item, false)}}},[_c('v-icon',[_vm._v("mdi-eye-off")])],1),_c('v-chip',{staticClass:"green lighten2"},[_vm._v("Опубликован")])],1):_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.publishPlace(item, true)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-chip',{staticClass:"grey darken-1"},[_vm._v("Не опубликован")])],1)]}},{key:"item.image",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":!!item.image ? 'success': 'error'}},[_vm._v(" "+_vm._s(item.image == null ? '' : item.image ? 'mdi-checkbox-marked-circle' : 'mdi-close')+" ")])]}},{key:"item.districtId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDistrict(item.districtId))+" ")]}}])})],1),_c('place-card',{attrs:{"create":_vm.createPlaceMode,"place":_vm.selectedPlace,"place-type":_vm.placeType},on:{"open":_vm.open,"refresh":_vm.refresh},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }