import axios from "@/plugins/axios";

export const users = {
    namespaced: true,
    state: () => ({
        users: [],
    }),
    getters: {
        users: state => state.users,
        userById: state => id =>  {
            console.log("Load user", id, state.users)
            return state.users?.find(x => x.id === id);
        }
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
    },
    actions: {
        getUsers({commit}, page) {
            return new Promise((resolve, reject) => {
                axios.get(`Account/GetPaging?page=${page}&pageSize=1000&showInvisible=true`)
                    .then(resp => {
                        commit('setUsers', resp.data.items)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        //obsolete
        getUser({commit}, id) {
            let data = {id}
            return new Promise((resolve, reject) => {
                axios.post('admin/users/get', data)
                    .then(resp => {
                        console.log(resp.data, commit);
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        //obsolete
        createUser(_, options) {
            return new Promise((resolve, reject) => {
                axios.post('admin/users/registerUser', options)
                    .then(resp => {
                        console.log(resp.data);
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        banUser(_, {email, isBanned}){
            return new Promise((resolve, reject) => {
                axios.post(`Account/BanUser/?email=${email}&isBanned=${isBanned}`, {email: email, isBanned: isBanned})
                    .then(resp => {
                        console.log(resp.data);
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        deleteUser(_, id){
            return new Promise((resolve, reject) => {
                axios.delete(`Account/${id}`)
                    .then(resp => {
                        console.log(resp.data);
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        manageRole(_, {roleName, email, hasOption}){
            return new Promise((resolve, reject) => {
                axios.post(`Account/ManageRole?email=${email}&roleName=${roleName}&hasOption=${hasOption}`)
                    .then(resp => {
                        console.log(resp.data);
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },

    }
}
