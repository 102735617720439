<template>
  <v-container fluid>
    <v-row>
      <v-col
          cols="12"
      >
        <v-card id="auth" class="mx-auto my-12" flat width="600">
          <v-card-title>{{ resultText }}</v-card-title>
          <div v-show="canLogin">
            <v-card-text>
              <v-btn text @click="$router.push('/')">Войти</v-btn>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Confirm",
  data() {
    return {
      resultText: "Отправляем",
      canLogin: false
    }
  },
  methods: {
    ...mapActions({
      confirm: 'api/confirmEmail'
    })
  },
  mounted() {
    this.confirm({userId: this.$route.query.u, signature: this.$route.query.s})
        .then(() => {
          this.resultText = "Спасибо за подтверждение!"
          this.canLogin = true;
        })
        .catch(() => this.resultText = "Ошибка")
  }
}
</script>

<style scoped>

</style>
