<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels>
            <v-expansion-panel v-for="(tag, index) in tagTypes" :key="index">
              <v-expansion-panel-header>
                {{ tag.title }}
                <div class="d-flex justify-end">
                  <v-chip
                      v-for="tagType in tag.items"
                      :key="tagType.tagName"
                      class="mx-1 pa-1"
                      small>{{ tagType.caption }}
                  </v-chip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel v-for="tagType in tag.items"
                                     :key="tagType.tagName">
                    <v-expansion-panel-header>{{ tagType.caption }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <category-table
                          :tag-name="tagType.tagName"
                      ></category-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CategoryTable from "@/components/CategoryTable";
import {mapActions} from "vuex";

export default {
  name: "Categories",
  methods: {
    ...mapActions(
        {loadTags: 'places/loadTags',}
    )
  },
  mounted() {
    this.loadTags('cities')
    this.loadTags('SubjectName')
    this.loadTags('subject')
  },
  data() {
    return {
      tagTypes: [
        {
          title: "Общее",
          items: [
            {caption: "Районы", tagName: "district"},
            {caption: "Города", tagName: "cities"},
          ]
        },
        {
          title: "Достопримечательности",
          items: [
            {caption: "Тип места", tagName: "placeType"},
            {caption: "Тематика", tagName: "subject"},
          ]
        },
        {
          title: "Отели",
          items: [
            {caption: "Тип снаряжения", tagName: "equipmentType"},
            {caption: "Тип заведение", tagName: "HousingType"},
            {caption: "Тип сервиса", tagName: "ServiceType"},
          ]
        },
        {
          title: "Рестораны",
          items: [
            {caption: "Тип заведения", tagName: "CateringType"},
            {caption: "Кухня", tagName: "CuisineType"},
            {caption: "Особые условия", tagName: "DenyType"},
          ]
        },
        {
          title: "Маршруты",
          items: [
            {caption: "Категории людей", tagName: "peopleType"},
            {caption: "Возрасты", tagName: "ageType"},
            {caption: "Тип тематики", tagName: "SubjectType"},
            {caption: "Тематика", tagName: "SubjectName"},

          ]
        }
      ]
    }
  },
  components: {CategoryTable}
}
</script>

<style scoped>

</style>
