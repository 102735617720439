<template>
  <div>
    <v-app-bar
        app
        dense
        hide-on-scroll>
      <v-app-bar-nav-icon @click.stop="setNavDrawerMode(!drawer)"></v-app-bar-nav-icon>
    </v-app-bar>
    <Navigation/>
    <v-main>
      <router-view class="px-md-16 pa-0"></router-view>
    </v-main>
    <FooterComponent/>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation"
import {mapActions, mapMutations, mapState} from "vuex";
import FooterComponent from "@/components/FooterComponent";

export default {
  name: 'MainContext',
  components: {FooterComponent, Navigation},
  data() {
    return {
      mini: true
    };
  },
  computed: {
    ...mapState({
      token: state => state.api.token,
      drawer: state => state.navigation.drawer,
      authorization: state => state.api.authorization,
      waitingForResponse: state => state.api.waitingForResponse
    })
  },
  methods: {
    ...mapActions({
      getUserInfo: 'api/getUserInfo'
    }),
    ...mapMutations({
      setNavDrawerMode:
          'navigation/setNavDrawerMode',
      setNavSelectedItem: 'navigation/setNavSelectedItem',

    }),
    requestUpdate() {
      console.log("REQUEST UPDATE");
    }
  },
  mounted() {
    this.requestUpdate();
    //this.loadToken();
    //this.setToken();
    this.getUserInfo();
  }
}
</script>
