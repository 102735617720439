export const navigation = {
    namespaced: true,
    state: () => ({
        drawer: false,
        selectedItem: -1,
        isAdmin: false
    }),
    mutations: {
        setNavDrawerMode(state, mode) {
            state.drawer = mode;
        },
        setNavSelectedItem(state, index) {
            state.selectedItem = index
        },
        setAdminMode(state, mode){
            console.log("SET ADMIN", mode)
            state.isAdmin = mode
        }
    }
}
