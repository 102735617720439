<template>
  <reviews-table v-if="init"></reviews-table>
</template>

<script>
import ReviewsTable from "@/components/ReviewsTable";
import {mapActions} from "vuex";

export default {
  name: "Reviews",
  components: {ReviewsTable},
  data() {
    return {
      init: false
    }
  },
  mounted() {
    this.getUsers(1).then(() => this.init = true)
  },
  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
    })
  }
};
</script>

<style scoped>

</style>
