<template>
  <v-card>
    <v-card-actions>
      <v-btn @click="loadUsers">Обновить</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="users"
          :loading="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          loading-text="Загрузка..."
      >
        <template v-slot:item.userName="{ item }">
          <user-card :user="item" @refresh="loadUsers"></user-card>
          {{ item.userName }}
          <v-chip v-show="item.isBanned" :color="bannedColor">Бан</v-chip>
        </template>
        <template v-slot:item.last_online_date="{ item }">
          {{ formatDay(item.last_online_date) }}
        </template>
        <template v-slot:item.roleName="{ item }">
          <v-chip :color="roleCaptions[item.roleName].color">{{ roleCaptions[item.roleName].caption }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserCard from "@/components/UserCard";
import {formatDateFromMs} from "@/plugins/formatters";
import colors from "vuetify/lib/util/colors";

export default {
  name: "UsersTable",
  components: {

    UserCard
  },
  data() {
    return {
      sortBy: 'last_online_date',
      sortDesc: true,
      headers: [
        {text: 'Имя', value: 'userName'},
        //{ text: 'Id', value: 'id' },
        {text: 'Email', value: 'email'},
        {text: 'Подтверждено', value: 'emailConfirmed'},
        {text: 'Создан', value: 'registrationDate'},
        {text: 'Роль', value: 'roleName'},
        {text: 'Платформа', value: 'platform'},
      ],
      loading: true,
      roleCaptions: {
        user: {
          caption: "Пользователь",
          color: colors.grey.lighten2
        },
        admin: {
          caption: "Администратор",
          color: colors.red.darken1
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/users'
    })
    , bannedColor() {
      return colors.deepOrange.lighten2
    }
  },
  methods: {
    ...mapActions({
      getUsers: 'users/getUsers'
    }),
    formatDay(obj1) {
      return formatDateFromMs(obj1);
    },
    loadUsers() {
      this.loading = true;
      this.getUsers(1).then(() => {
        //console.log("loaded", val)
        this.loading = false;
      });
    }
  },
  mounted() {
    this.loadUsers()
  }

}
</script>

<style scoped>

</style>
