<template>
  <v-select
      v-model="iValue"
      :items="items"
      :rules="[v => !!v || 'Обязательное поле']"
      item-text="name"
      item-value="id"
      :label="label"
      required
  ></v-select>
</template>
<script>
export default {
  name: 'mono-select',
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    },
    items: {
      type: Array,
      required: true
    },
    label:{
      type: String,
      required: true
    }
  },
  computed: {
    iValue: {
      get() {
        console.log("GET MONO VALUE", this.value)
        return this.value;
      },
      set(val) {
        console.log("SET MONO VALUE", val)
        this.internalValue = val;
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      internalValue: 0
    }
  }
}
</script>
