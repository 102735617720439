<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          icon
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>
    <v-skeleton-loader
        v-if="!loaded"
        max-width="500"
        type="heading, table-cell@10"></v-skeleton-loader>
    <v-card v-else>
      <v-card-title>
        {{ user.nickname }}
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr v-for="(caption, name) in userCaptions" :key="name">
              <td>{{ caption }}</td>
              <td>{{ user[name] }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
            color="warning"
            outlined
            @click="banUser({loginEmail: user.email, isBanned: !user.isBanned}) + refresh()"
        >
          {{ user.isBanned ? 'Разбанить' : 'Забанить' }}
        </v-btn>
        <v-btn
            color="error"
            outlined
            @click="deleteUser(user.id) + refresh() +close() "
        >
          Удалить
        </v-btn>
        <v-btn
            v-if="canCreateAdmins"
            color="accent"
            outlined
            @click="changeRole(user.email, user.roleName!=='admin')">
          {{ user.roleName === 'admin' ? 'В юзеры' : 'В админы' }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            outlined
            @click="close"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
//import {formatDateFromMs} from "@/plugins/formatters";

export default {
  name: "UserCard",
  props: {
    user: Object
  },
  data() {
    return {
      dialog: false,
      loaded: true,
      userCaptions: {
        id: "Id",
        userName: "Имя",
        email: "Email",
        emailConfirmed: "Подтвержден",
        registrationDate: "Регистрация",
        platform: "Платформа",
        roleName: "Роль",
        isBanned: "Бан",
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
      deleteUser: 'users/deleteUser',
      banUser: 'users/banUser',
      manageRole: 'users/manageRole',
    }),
    close() {
      this.dialog = false;
    },
    refresh() {
      setTimeout(() => this.$emit('refresh'), 500)
    },
    async changeRole(email, hasOption) {
      await this.manageRole({roleName: 'user', email, hasOption: !hasOption})
      await this.manageRole({roleName: 'admin', email, hasOption})
      this.refresh();
    }
  },
  computed: {
    ...mapGetters({
      isSuperUser: 'api/isSuperUser',
      canCreateAdmins: 'api/canCreateAdmins'
    }),
    isDebug() {
      return this.isSuperUser || process.env.NODE_ENV === 'development';
    },
  }
}
</script>

<style scoped>

</style>
