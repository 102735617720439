<template>
  <v-list-item v-if="place">

    <v-list-item-icon><v-text-field v-model="order" hint="Порядок" dense @change="onChange"></v-text-field></v-list-item-icon>
    <v-list-item-content>{{ place.name }}</v-list-item-content>
    <v-list-item-action>

      <v-btn icon @click="$emit('delete', relation.attractionId)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AttractionListItem",
  props: {
    relation: {
      required: true,
      type: Object
    }
  },
  methods: {
    ...mapActions({
      loadPlaceById: 'places/loadPlaceById'
    })
    , onChange(val) {
      console.log('CHANGED', val);
      if (val != null && !this.isEmptyOrSpaces(val))
        this.$emit('changed', val, this.relation.attractionId);
    },
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    }
  },
  data() {
    return {
      loading: true,
      place: null,
      order: 0
    }
  },
  created() {
    this.order = this.relation.order
    this.loadPlaceById({placeType: 'Attraction', id: this.relation.attractionId})
        .then(resp => this.place = resp);
  }
}
</script>

<style scoped>

</style>
