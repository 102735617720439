<template>
  <v-card class="my-3 elevation-0">
    <v-card-actions>
      <v-btn v-show="isDebug" color="error" @click="deleteAll">Удалить всё</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mx-1" @click="getTags">Обновить</v-btn>
      <v-btn class="mx-1" @click="createTag">Добавить</v-btn>
    </v-card-actions>
    <template>
      <v-data-table
          :headers="headers"
          :items="currentTags"
          :items-per-page="500"
          :sort-by="['id']"
          class="elevation-0"
          hide-default-footer
          @click:row="handleClick"
      >
        <template v-slot:item.cover="{ item }">
          <file-storage-image
              :key="refreshKey + item.id"
              :file-name="item.id.toString()"
              :height="50"
              :path="tagName.toLowerCase()"
              style="width: 50px"
              type="Icon"
          ></file-storage-image>
        </template>

      </v-data-table>
    </template>
    <v-dialog
        v-model="dialog" width="500">
      <v-card>
        <v-card-title>{{ createMode ? 'Создать' : 'Редактировать' }}</v-card-title>
        <v-card-text>
        <image-upload
            v-if="createMode===false"
            :key="Date.now()"
            :file-name="selectedTag.id.toString()"
            :path="tagName.toLowerCase()"
            type="Icon"
        ></image-upload>
        </v-card-text>
        <v-card-text>
          <v-text-field
              v-model="selectedTag.name"
              autocomplete="off"
              label="Имя"
          ></v-text-field>
          <v-text-field
              v-model="selectedTag.weight"
              autocomplete="off"
              label="Вес"
              type="number"
          >

          </v-text-field>
          <div v-if="tagName==='placeType' && createMode===false">
            <multi-select-raw
                ref="placeType"
                :items="tags('subject')"
                :parent-id="selectedTag.id.toString()"
                :raw-value="placeTypeEditor.selectedValuesRaw"
                :value="placeTypeEditor.selectedValues"
                label="Тематики"
                relation-child-name="subject"
                relation-parent-name="placeType"
                tag-name="subject"></multi-select-raw>
          </div>
          <div v-if="tagName==='district' && createMode===false">
            <multi-select-raw
                ref="district"
                :items="tags('cities')"
                :parent-id="selectedTag.id.toString()"
                :raw-value="districtEditor.selectedValuesRaw"
                :value="districtEditor.selectedValues"
                label="Города"
                relation-child-name="city"
                relation-parent-name="district"
                tag-name="cities"></multi-select-raw>
          </div>
          <div v-if="tagName==='SubjectType' && createMode===false">
            <multi-select-raw
                ref="SubjectType"
                :items="tags('SubjectName')"
                :parent-id="selectedTag.id.toString()"
                :raw-value="subjectTypeEditor.selectedValuesRaw"
                :value="subjectTypeEditor.selectedValues"
                label="Тематики"
                relation-child-name="subjectName"
                relation-parent-name="subjectType"
                tag-name="SubjectName"
            ></multi-select-raw>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="dialog=false">Отмена</v-btn>
          <v-btn color="error" @click="dTag">Удалить</v-btn>
          <v-spacer/>
          <v-btn color="success" @click="performAction">Подтвердить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MultiSelectRaw from "@/components/fields/MultiSelectRaw";
import FileStorageImage from "@/components/widgets/FileStorageImage";
import ImageUpload from "@/components/fields/ImageUpload";

export default {
  name: "CategoryTable",
  components: {ImageUpload, FileStorageImage, MultiSelectRaw},
  props: {
    tagName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      tags: 'places/tags',
      isSuperUser: 'api/isSuperUser'
    }),
    isDebug() {
      return this.isSuperUser || process.env.NODE_ENV === 'development';
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal)
        this.getTags();
    }
  },
  methods: {
    async deleteAll() {
      for (const tag of this.currentTags) {
        console.log(tag)
        await this.deleteTag({path: this.tagName, id: tag.id});
        //await this.deletePlace({placeType: this.placeType, placeId: place.id})
      }
      await this.getTags()
    },
    async getTags() {
      console.log(this.tagName);
      let loadedTags = await this.loadTags(this.tagName);

      let tagIcons = [];

      try {
        tagIcons = await this.fetchFiles({type: "Icon", path: this.tagName});
      } catch (e) {
        console.log("No icons", e);
      }

      console.log(tagIcons)


      this.currentTags = loadedTags.data.items;
      this.refreshKey = Date.now()

    },
    ...mapActions({
      loadTags: 'places/loadTags',
      addTag: 'places/createTag',
      changeTag: 'places/changeTag',
      deleteTag: 'places/deleteTag',
      loadTagsRaw: 'places/loadTagsRaw',
      fetchFiles: 'files/fetchFiles'
    }),
    handleClick(value) {
      console.log(value)
      this.createMode = false;
      this.selectedTag = value;

      if (this.tagName === 'placeType') {
        this.loadTagsRaw({
          relationName: 'placeTypeSubject',
          id: value.id,
          field: 'placeTypeId'
        })
            .then(res => {
              console.log('RESP', res);
              this.placeTypeEditor.selectedValues = res.data.items.map(x => x.subjectId);
              this.placeTypeEditor.selectedValuesRaw = res.data.items;
            })
      }

      if (this.tagName === 'district') {
        this.loadTagsRaw({
          relationName: 'DistrictCity',
          id: value.id,
          field: 'districtId'
        })
            .then(res => {
              console.log('RESP', res);
              this.districtEditor.selectedValues = res.data.items.map(x => x.cityId);
              this.districtEditor.selectedValuesRaw = res.data.items;
            })
      }

      if (this.tagName === 'SubjectType') {
        this.loadTagsRaw({
          relationName: 'SubjectTypeSubjectName',
          id: value.id,
          field: 'subjectTypeId'
        })
            .then(res => {
              console.log('RESP', res);
              this.subjectTypeEditor.selectedValues = res.data.items.map(x => x.subjectNameId);
              this.subjectTypeEditor.selectedValuesRaw = res.data.items;
            })
      }

      this.dialog = true
    },
    createTag() {
      this.createMode = true;
      this.selectedTag = {id: 0, name: ""}
      this.dialog = true
    },
    async dTag() {
      let handle = await this.deleteTag({path: this.tagName, id: this.selectedTag.id});

      if (handle.status === 200) {
        this.$vToastify.success("Сохранено");
        this.dialog = false;
        await this.getTags()
      }
    },
    async performAction() {
      let handle
      if (this.createMode) {
        handle = await this.addTag({path: this.tagName, value: this.selectedTag})
      } else {
        handle = await this.changeTag({path: this.tagName, value: this.selectedTag})
        if (this.tagName === 'district') {
          await this.$refs.district.save();
        }

        if (this.tagName === 'SubjectType') {
          await this.$refs.SubjectType.save();
        }

        if (this.tagName === 'placeType') {
          await this.$refs.placeType.save();
        }
      }

      console.log(handle)

      if (handle.status === 200) {
        this.$vToastify.success("Сохранено");
        this.dialog = false;
        await this.getTags()
      }
    }
  },
  data() {
    return {
      dialog: false,
      currentTags: [],
      headers:
          [
            {text: 'cover', value: 'cover'},
            {text: 'Id', value: 'id', width: 50},
            {text: 'Имя', value: 'name'},
          ],
      createMode: false,
      selectedTag: {id: 0, name: ""},
      districtEditor: {
        selectedValues: [],
        selectedValuesRaw: []
      },
      subjectTypeEditor: {
        selectedValues: [],
        selectedValuesRaw: []
      },
      placeTypeEditor: {
        selectedValues: [],
        selectedValuesRaw: []
      },
      refreshKey: ""
    }
  },
  mounted() {
    this.getTags()
  },

}
</script>

<style scoped>

</style>
