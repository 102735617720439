import axios from "@/plugins/axios";

export const files = {
    namespaced: true,
    actions: {
        ///types = None, Picture, Audio, Avatar, Icon
        fetchFiles(_, {type, path}) {
            return new Promise((resolve, reject) => {
                axios.get(`FileStorage?type=${type}&path=${path}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getFile(_, {type, path, fileName}) {
            return new Promise((resolve, reject) => {
                axios.post(`FileStorage?type=${type}&path=${path}&fileName=${fileName}`, {}, {
                    responseType: 'arraybuffer'
                })
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        uploadFile(_, {type, path, fileName, formData}) {
            return new Promise((resolve, reject) => {
                axios.post(`FileStorage/Upload?type=${type}&path=${path}&fileName=${fileName}`,
                    formData, {
                        headers: {
                            'Content-Type': 'application/octet-stream',
                        }
                    })
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        deleteFile(_, {type, path, fileName}) {
            return new Promise((resolve, reject) => {
                axios.delete(`FileStorage?type=${type}&path=${path}&fileName=${fileName}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        }
    }
}
