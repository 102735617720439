<template>
  <v-footer fixed app padless>
    <v-container class="d-flex justify-center">
<!--      <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          height="24"
          src="../assets/logo.png"
          width="24"
      />-->
      Novgorod.travel
    </v-container>
  </v-footer>
</template>s
<script>
export default {
  name: 'FooterComponent'
}
</script>
