<template>
  <v-list-item>
    <v-list-item-title>{{ fileName }}</v-list-item-title>
    <v-list-item-action>
      <v-btn icon>
        <v-icon @click="delFile">mdi-delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "FileStorageFile",
  props: {
    path: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      image: null
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.image = null
      if (!this.path) {
        this.displayNoImage();
      } else
        this.fetchFiles({type: this.type, path: this.path})
            .then(data => this.displayImage(data))
            .catch(() => this.displayNoImage())
    },
    displayImage(data) {
      const app = this;
      console.log(data.data);
      if (data.data.some(x => x.fileName === this.fileName)) {
        this.getFile({type: this.type, path: this.path, fileName: this.fileName})
            .then(file => {
              const bytes = new Uint8Array(file.data);
              const binary = bytes.reduce((data, b) => data + String.fromCharCode(b), '');
              app.image = "data:image/jpeg;base64," + btoa(binary);
              this.$emit('loaded', true);
            })
      } else {
        this.displayNoImage()
      }
    },
    displayNoImage() {
      this.$emit('loaded', false);
      console.log("No image found");
      this.image = "./images/no-image.jpg";
    },
    ...mapActions({
      fetchFiles: 'files/fetchFiles',
      getFile: 'files/getFile',
      deleteFile: 'files/deleteFile'
    }),
    async delFile() {
      await this.deleteFile({type: this.type, path: this.path, fileName: this.fileName});
      this.$emit('refresh');
    }
  }
}
</script>

<style scoped>

</style>
