<template>
  <v-container class="px-5">
    <v-row align-content="center" justify="center">
      <v-file-input v-model="file" :loading="uploadInProgress"></v-file-input>

      <v-btn @click="upload">
        <v-progress-circular v-if="uploadInProgress" :value="100*uploaded/total"></v-progress-circular>
        {{ uploadInProgress ? 'Cancel' : 'Upload' }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "@/plugins/axios";
export default {
  name: "RestaurantLoader",
  data() {
    return {
      file: null,
      data: {},
      uploadInProgress: false,
      total: 0,
      uploaded: 0
    }
  },
  methods: {
    ...mapActions({
      createTag: 'places/createTag',
      changePlace: 'places/changePlace',
      loadTags: 'places/loadTags',
      createPlace: 'places/createPlace',
      addTagById: 'places/addTagById',
      fetch: 'files/fetchFiles',
      searchPlace: 'places/searchPlace',
      addTagRaw: 'places/addTagRaw'
    }),
    upload() {
      if (this.uploadInProgress) {
        this.uploadInProgress = false;
        return;
      }

      this.uploadInProgress = true;

      const reader = new FileReader();
      const app = this;
      reader.onload = async function (event) {
        app.data = JSON.parse(event.target.result.toString());
        console.log('File content:', app.data);
        app.total = app.data.length;
        app.uploaded = 0;

        const unique = [...new Set(app.data.map(item => item.city))];
        console.log(unique.length, unique);
        console.log(JSON.stringify(unique))

        const innerPlaceType = 'Restaurant';
        for (let item of app.data) {
          if (!app.uploadInProgress) return;

          //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          //warning delete all before proceed

          /*const existing = await app.getExisting(item, innerPlaceType);
          if (existing.items.length > 0) {
            await app.editPlace(existing.items[0], item, innerPlaceType);
          } else*/ {
            await app.uploadPlace(item, innerPlaceType);
          }
          app.uploaded += 1;
        }

        app.uploadInProgress = false;
        app.$emit('done')
      };
      reader.readAsText(this.file);
    },
    async uploadPlace(place, innerPlaceType) {
      console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++", place)

      place['path'] = place.id;

      place['cityId'] = await this.getTagId('Cities', place.city);
      const items = await axios.get("DistrictCity?page=1&pageSize=1000");
      console.log(place['cityId']);
      const i = items.data.items.find(x => x.cityId===place['cityId']);
      console.log(i)
      if (i!=null)
        place['districtId'] = i.districtId;

     /* place['districtId'] = await this.getTagId('District', place.district );
      place['cityId'] = await this.getTagId('Cities', place.city, 'District', place['districtId'], 'City');*/

      place['cateringTypeId'] = await this.getTagId('cateringType', place.type_catering );

      place.id = 0;

      place['discount'] = 0;

      const {placeType, subjects, district, city, ...placeDto} = place;

      console.log(placeDto, placeType, subjects, district, city);

      console.log("Ready for upload", placeDto)

      const newPlace = (await this.createPlace({placeType: innerPlaceType, place: placeDto})).data;

      console.log("Received", newPlace)

      await this.SaveMulti(newPlace.id, place, 'cuisineType', 'type_cuisine', innerPlaceType);
      await this.SaveMulti(newPlace.id, place, 'denyType', 'type_deny', innerPlaceType);

    },
    async UpdateMulti(oldTags, place, tagName, initName, innerPlaceType) {
      let newTags = [];

      console.log('OLDTAGS', oldTags);

      for (let tag of place[initName]) {
        const capitalTag = capitalizeFirstLetter(tag);
        console.log(capitalTag)
        newTags.push(await this.getTagId(tagName, capitalTag))
      }

      const added = newTags.filter(x => !oldTags.includes(x));
      const deleted = oldTags.filter(x => !newTags.includes(x));

      console.log("ADDED", added, "DELETED", deleted, innerPlaceType);

      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
    ,
    async SaveMulti(id, place, tagName, initName, innerPlaceType) {
      let newTags = [];

      console.log("================", place, initName, id, innerPlaceType)

      const arr = place[initName].split(",").map(function(item) {
        return item.trim();
      });

      for (let tag of arr) {
        const capitalTag = capitalizeFirstLetter(tag);
        console.log(capitalTag)
        newTags.push(await this.getTagId(tagName, capitalTag))
      }

      console.log("=============", newTags)

      const result = await this.addTagById({
        placeType: innerPlaceType,
        placeId: id,
        tagName: tagName,
        tags: newTags
      });

      console.log(newTags, result)

      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
    ,
    async getTagId(path, tagName, parentPath, parentTagId, tagPathOverride) {
      console.log("Get tag id", path);
      let tag = this.tags(path).find(x => x.name.includes(tagName));


      if (tag == null) {
        const resp = await (this.createTag({path: path, value: {id: 0, name: tagName, weight: 1}}))
        tag = resp.data;
        if (parentPath!=null && parentTagId!=null){
          console.log("UPDATING RELATIONS", parentPath, path, parentTagId, tag.id)
          const res = await this.addTagRaw({
            parentName: parentPath,
            childName: tagPathOverride ?? path,
            parentId: parentTagId,
            childId: tag.id
          })
          console.log("UPDATED", res)
        }
        await this.loadTags(path)
      }

      return tag.id;
    },
    async getExisting(item, innerPlaceType) {
      const query = {
        "order": {"propertyName": "name", "isDes": false}, "filter": {
          "type": "Binary",
          "unit1": {"type": "Parameter", "PropertyName": "path"},
          "unit2": {"type": "Const", "value": item.id}, "operatorType": "Equals"
        }
      };

      return await this.searchPlace({placeType: innerPlaceType, query: query});
    },
    async editPlace(existingItem, newItem, innerPlaceType) {
      console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^", existingItem, newItem, innerPlaceType);

      //newItem['districtId'] = await this.getTagId('District', newItem.district);
      //newItem['cityId'] = await this.getTagId('Cities', newItem.city, 'District', newItem['districtId']);
      newItem['cityId'] = await this.getTagId('Cities', newItem.city);
      const items = await axios.get("DistrictCity?page=1&pageSize=1000");
      const i = items.data.items.find(x => x.cityId===newItem['cityId']);
      console.log(i);
      newItem['districtId'] = i.districtId;

      newItem['id'] = existingItem.attraction.id;

      newItem['housingTypeId'] = await this.getTagId('HousingType', newItem.type_housing );

      newItem['discount'] = newItem.card;

      if (newItem.duration == null) newItem.duration = existingItem.attraction.duration;

      const {placeType, subjects, district, city, ...placeDto} = newItem;

      console.log("DECOMPOSED", placeDto, placeType, subjects, district, city);

      console.log("Ready for upload", placeDto)

      const updatedPlace = (await this.changePlace({placeType: innerPlaceType, place: placeDto})).data;

      console.log("Received", updatedPlace)

      await this.UpdateMulti(existingItem.subjectIds, newItem, 'Subject', 'subjects', innerPlaceType);
      await this.UpdateMulti(existingItem.placeTypeIds, newItem, 'PlaceType', 'placeType', innerPlaceType);
    }
  },
  computed: {
    ...mapGetters({
      tags: 'places/tags'
    })
  },
}
</script>

<style scoped>

</style>
