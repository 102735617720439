<template>
  <div>
    <v-card>
      <v-card-title>Список достопримечательностей</v-card-title>
      <v-card-subtitle>Обязательно сохраните карточку после создания точек или
        изменения порядка
      </v-card-subtitle>

      <route-loader :id="placeId"
                    @onChange="changeHandle">
      </route-loader>

      <v-card-subtitle>
        Чем выше число, тем ниже в списке.
      </v-card-subtitle>
      <v-card-actions>
        <v-btn color="warning" @click="deleteAll">Удалить все места</v-btn>
      </v-card-actions>
      <v-list>
        <attraction-list-item
            v-for="relation of newVal == null? internalValue : newVal"
            :key="relation.attractionId"
            :relation="relation"
            @changed="onChange"
            @delete="deleteAttr"
        ></attraction-list-item>
      </v-list>
      <v-card-actions>
        <v-text-field
            v-model="idToAdd"
            append-icon="mdi-upload"
            label="Введите id"
            type="number"
            @click:append="addAttraction"></v-text-field>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AttractionListItem from "@/components/fields/AttractionListItem";
import RouteLoader from "@/components/debug/RouteLoader";

export default {
  name: 'attractions-select',
  components: {RouteLoader, AttractionListItem},
  props: {
    label: {
      type: String,
      required: true
    },
    placeId: {
      type: String,
      required: true
    }
  },
  watch: {
    placeId(oldVal, newVal) {
      console.log("PLACE CHANGED", newVal, oldVal);
      this.refresh();
    }
  },
  computed: {
    ...mapGetters({
      isSuperUser: 'api/isSuperUser'
    }),
    isDebug() {
      return this.isSuperUser || process.env.NODE_ENV === 'development';
    },
    internalValue: {
      get() {
        console.log('getValue', this.value)
        return this.value;
      },
      set(val) {
        console.log('setValue', this.newVal, val)
        this.newVal = val;
        console.log('setValue', this.newVal, this.value)
        this.$emit('input', val);
      }
    }
  },
  methods: {
    async save() {
      const newVal = this.newVal;
      if (newVal == null) return;

      const oldVal = this.value
      console.log("NEW, OLD", newVal, oldVal);
      const added = newVal.filter(x => {
        return (oldVal == null || oldVal.length === 0 || !oldVal.some(z => z.attractionId === x.attractionId));
      });

      console.log(added);

      if (added && added.length > 0) {
        console.log("Added:" + added)
        await this.addTagById({
          placeType: this.placeType,
          placeId: this.placeId,
          tagName: 'Attraction',
          tags: added.map(x => x.attractionId)
        })
      }

      const deleted = oldVal.filter(x => {
        if (newVal == null || newVal.length === 0) return true;
        return (!newVal.some(z => z.attractionId === x.attractionId));
      });

      if (deleted && deleted.length > 0) {

        console.log("Deleted:", deleted)
        console.log(deleted.map(x => x.attractionId));
        await this.deleteTagById({
          placeType: this.placeType,
          placeId: this.placeId,
          tagName: 'Attraction',
          tags: deleted.map(x => x.attractionId)
        })
      }

      for (const i of newVal) {
        console.log(i);
        await this.updateRouteOrder({
          attractionId: i.attractionId,
          routeId: i.routeId,
          order: i.order
        });
      }
      return Promise.resolve();
    },
    changeHandle(newVal) {
      this.internalValue = newVal.map(e => this.createItem(e));
    },
    createItem(e) {
      return {
        order: e.order, id: 0, attractionId: parseInt(e.id),
        routeId: this.placeId
      }
    },
    ...mapActions({
      addTagById: 'places/addTagById',
      deleteTagById: 'places/deleteTagById',
      getRouteAttractions: 'places/getRouteAttractions',
      updateRouteOrder: 'places/updateRouteOrder'
    }),
    addAttraction() {
      this.internalValue = [...this.internalValue, {
        order: 0, id: 0, attractionId: parseInt(this.idToAdd),
        routeId: this.placeId
      }];
      console.log(this.idToAdd);
    },
    deleteAttr(id) {
      if (this.newVal == null) this.newVal = [...this.internalValue];
      const existing = this.newVal.findIndex(x => x.attractionId === id);

      console.log(existing, this.newVal.length);
      if (existing >= 0) {
        if (existing === 0 && this.newVal.length === 1) {
          this.newVal = [];
        } else
          this.newVal.splice(existing, 1);
      }
    },
    async refresh() {
      const attr = await this.getRouteAttractions(this.placeId);
      this.value = attr.data.items.sort((a, b) => a.order - b.order);
    },
    onChange(val, attrId) {
      var attrIndex = this.newVal.findIndex(x => x.attractionId === attrId);
      console.log("CHANGED", val, attrId);
      console.log("INDEX", attrIndex);
      this.newVal[attrIndex].order = val;
      this.internalValue = [...this.newVal]
    },

    deleteAll() {
      this.newVal = [];
    }
  },
  data() {
    return {
      placeType: 'route',
      newVal: null,
      items: [],
      loaded: false,
      idToAdd: 0,
      value: []
    }
  },
  mounted() {
    this.refresh()
  }
}
</script>
<style>

</style>
