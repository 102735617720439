<template>
  <v-container class="px-5">
    <v-row align-content="center" justify="center">
      <v-file-input
          v-model="file"
          accept=".json"
          class="px-2"
          hint="Загрузка мест из файла JSON"
          persistent-hint></v-file-input>
      <v-btn :disabled="file==null" @click="upload">Upload</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RouteLoader",
  data() {
    return {
      file: null,
      data: {}
    }
  },
  props: ['id']
  ,
  methods: {
    ...mapActions({
      createTag: 'places/createTag',
      loadTags: 'places/loadTags',
      createPlace: 'places/createPlace',
      addTagById: 'places/addTagById',
      fetch: 'files/fetchFiles',
      searchPlace: 'places/searchPlace'
    }),
    async upload() {
      const reader = new FileReader();
      const app = this;
      reader.onload = async function (event) {
        const result = '{"data": ' + event.target.result.toString() + "}";

        app.data = JSON.parse(result);
        //console.log('File content:', this.data);


        const findDuplicates = (arr) => {
          let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
          // JS by default uses a crappy string compare.
          // (we use slice to clone the array so the
          // original array won't be modified)
          let results = [];
          for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
              results.push(sorted_arr[i]);
            }
          }
          return results;
        }

        let res = [];

        for (let item of app.data.data) {
          console.log(item)

          const query = {
            "order": {"propertyName": "name", "isDes": false}, "filter": {
              "type": "Binary",
              "unit1": {"type": "Parameter", "PropertyName": "path"},
              "unit2": {"type": "Const", "value": item.id}, "operatorType": "Equals"
            }
          };

          const place = await app.searchPlace({placeType: 'Attraction', query: query})

          console.log(place)
          if (place?.items?.length > 0)
            res.push({id: place.items[0].attraction.id, order: item.order});
          else {
            app.$vToastify.warning({
              mode: "prompt",
              body: `Место не найдено в базе: ${item.name}</br>Район: ${item.district}</br> Город: ${item.city}`,
              answers: {OK: true},
              iconEnabled: false
            });
          }

        }

        const dupt = findDuplicates(res.map(x => x.id))

        if (dupt.length > 0) {
          app.$vToastify.error({
            mode: "prompt",
            body: `Найдены дубликаты ${dupt}`,
            answers: {OK: true},
            iconEnabled: false
          });
          return;
        }

        app.$emit('onChange', [...new Set(res)]);
      };
      reader.readAsText(this.file);
    }

  },
  computed: {
    ...mapGetters({
      tags: 'places/tags'
    })
  },
}
</script>

<style scoped>

</style>
