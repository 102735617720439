<template>
  <v-navigation-drawer
      :value="drawer"
      app
      temporary
      class="nav-list"
      @input="changeDrawer">
    <v-list class="py-0">
      <v-list-item class="nav-title"></v-list-item>
      <v-list-item-group
          :value="selectedItem"
          color="primary"
          @change="setSelected"
      >
        <v-list-item
            v-for="route in routes"
            :key="route.path"
            link
            v-show="canDisplay(route)"
            @click="$router.push(route.path)"
        >
          <v-list-item-icon>
            <v-icon>{{ route.meta.icon || "mdi-menu-right" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item
          link
          @click="changeDrawer(false) + logOut()">
        <v-list-item-icon>
          <v-icon>mdi-close</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Выйти</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'Navigation',
  data: () => ({}),
  methods: {
    ...mapMutations({
      setSelected: 'navigation/setNavSelectedItem',
      changeDrawer: 'navigation/setNavDrawerMode',
      logOut: 'api/logout'
    }),
    canDisplay(route) {
      return !route.meta.admin || (route.meta.admin && this.isAdmin)
    }
    ,
    getSelected() {
      return this.routes.findIndex(value => value.path === this.$route.path);
    }
  },
  computed: {
    ...mapState({
      drawer: state => state.navigation.drawer,
      selectedItem: state => state.navigation.selectedItem,
      isAdmin: state => state.navigation.isAdmin
    }),
    routes() {
      return this.$router.options.routes;
    }
  },
  mounted() {
    this.setSelected(this.getSelected())
  }
}
</script>
<style scoped>

</style>
