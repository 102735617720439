import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Index from './Index.vue'
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'
import axios from "@/plugins/axios";
import {users} from "@/store/users";
import {navigation} from "@/store/navigation";
import {api} from "@/store/api";
import {stats} from "@/store/stats";
import {router} from "@/plugins/router";
import VueToastify from "vue-toastify";
import {places} from "@/store/places";
import {files} from "@/store/files";
import {reviews} from "@/store/reviews";

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueToastify);

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

axios.interceptors.response.use((response) => response, (error) => {
    Vue.$vToastify.error(error?.response)
    if (error?.response?.status === 401) {
        store.commit('api/logout');
    }
    throw error;
});

const store = new Vuex.Store({
    modules: {
        navigation: navigation,
        api: api,
        users: users,
        stats: stats,
        places: places,
        files: files,
        reviews: reviews
    }
})

new Vue({
    vuetify,
    store,
    router,
    render: h => h(Index)
}).$mount('#app')
