<template>
  <v-container fluid>
    <v-row>
      <v-col
          cols="12"
      >
        <users-table></users-table>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
//import {mapActions, mapGetters} from "vuex";
import UsersTable from "@/components/UsersTable";

export default {
  name: "Users",
  components: {
    UsersTable
  },
  data() {
    return {}
  },
  /*  computed: {
      ...mapGetters({
        users: 'users/users'
      })
    },
    methods: {
      ...mapActions({
        getUsers: 'users/getUsers'
      }),
    },*/

}
</script>

<style scoped>

</style>
