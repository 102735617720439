export function formatDate(date) {
    if (!date) return ""
    const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
    return new Date(date).toLocaleDateString("ru-RU", options);
}

export function formatDateFromMs(ms){
    if (!ms || ms < 0) return ""
    return formatDate(new Date(ms))
}

export function formatDateFromString(dateString) {
    if (!dateString) return ""
    return formatDate(Date.parse(dateString));
}
