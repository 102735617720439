<template>
  <div>
    <v-card>
      <v-card-title>Аудио</v-card-title>
      <v-list>
        <file-storage-file
            v-for="(file, index) in files"
            :key="index"
            :file-name="file.fileName"
            :path="filePath"
            type="Audio"
            @refresh="refresh"
        ></file-storage-file>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <file-uploader
            @refresh="refresh"
            :file-path="filePath"
            file-type="Audio"
            accept-extensions=".mp3"
            :show-name-editor=true
        ></file-uploader>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import FileStorageFile from "@/components/widgets/FileStorageFile";
import {mapActions} from "vuex";
import FileUploader from "@/components/fields/FileUploader";

export default {
  name: "AudioList",
  components: {FileUploader, FileStorageFile},
  props: {
    filePath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      files: []
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      fetchFiles: 'files/fetchFiles'
    }),
    async refresh() {
      try {
        let files = await this.fetchFiles({type: 'Audio', path: this.filePath});
        this.files = files.data;
      }
      catch (e) {
        this.files = [];
      }
    },
  },
  watch: {
    filePath() {
      this.refresh();
    }
  }
}
</script>

<style scoped>

</style>
