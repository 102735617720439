<template>
  <v-card id="auth" class="mx-auto my-12" width="600">
    <v-card-title>{{ captions[authState] }}</v-card-title>
    <v-card-subtitle>
      <div v-if="authState==='login'">Нет аккаунта?
        <v-btn color="primary" elevation="0" small text
               @click="authState='register'">Зарегистрироваться
        </v-btn>
      </div>
      <div v-else-if="authState==='register'">Уже зарегистрированы?
        <v-btn color="primary" elevation="0" small text
               @click="authState='login'">Войти
        </v-btn>
      </div>
      <div v-else-if="authState==='confirm'">

      </div>
      <div v-else-if="authState==='recover'">
        Уже вспомнили?
        <v-btn color="primary" elevation="0" small text
               @click="authState='login'">Войти
        </v-btn>
      </div>
    </v-card-subtitle>
    <v-form ref="form"
            v-model="valid"
            autocomplete="off"
            role="presentation" @submit.prevent="doAction"
    >
      <v-card-text>
        <div v-if="authState==='login'">
          <v-text-field
              v-model="loginEmail"
              :rules="rules"
              label="Почта"
              required
              type="email"
          />
          <v-text-field
              v-model="loginPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules"
              :type="showPassword ? 'text' : 'password'"
              label="Пароль"
              required
              @click:append="showPassword = !showPassword"
          >
          </v-text-field>
          <v-card-subtitle><small>Забыли пароль?
            <v-btn color="primary" elevation="0" small text
                   @click="authState='recover'">Восстановить
            </v-btn>
          </small></v-card-subtitle>
        </div>
        <div v-else-if="authState==='register'">
          <v-text-field
              v-model="registerEmail"
              :rules="rules"
              autocomplete="none"
              label="Почта"
              required
              type="email"
          />
          <v-text-field
              v-model="registerUserName"
              :rules="rules"
              autocomplete="none"
              label="Имя пользователя"
              required
              type="nickname"
          />
          <v-text-field
              v-model="registerPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="none"
              label="Пароль"
              required
              @click:append="showPassword = !showPassword"
          >
          </v-text-field>
          <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[...rules, matchPassword]"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="none"
              label="Повторите пароль"
              required
              @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </div>
        <div v-else-if="authState==='recover'">
          <v-text-field
              v-model="recoverEmail"
              :rules="rules"
              autocomplete="none"
              label="Почта"
              required
              type="email"
          />
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" type="submit">{{ actionBtn[authState] }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "Auth",
  data: () => ({
    loginEmail: "",
    loginPassword: "",
    registerEmail: "",
    recoverEmail: "",
    registerPassword: "",
    registerUserName: "",
    showPassword: false,
    min: 3,
    valid: true,
    authState: "login",
    captions:
        {
          login: "Авторизация",
          register: "Регистрация",
          recover: "Восстановить пароль"
        },
    actionBtn: {
      login: "Войти",
      register: "Зарегистрироваться",
      recover: "Восстановить"
    }
  }),
  watch: {
    authState() {
      this.showPassword = false;
    }
  },
  methods: {
    doAction() {
      console.log(this.authState)
      switch (this.authState) {
        case "login":
          this.localLogin();
          break;
        case "register":
          this.localRegister();
          break;
        case "recover":
          this.localRecover();
          break;
      }

    },
    localLogin() {
      this.login({email: this.loginEmail, password: this.loginPassword})
          .then(() => {
            this.getUser()
                .then(u => {
                  const admin = u.data.roleName === 'admin';
                  console.log("USER is admin", admin);
                  this.setAdminMode(admin);
                })
          })
          .catch(() => {
            this.$vToastify.error("Неправильная почта или пароль");
          })
    },
    localRegister() {
      console.log("local register")
      this.register({email: this.registerEmail, password: this.registerPassword, userName: this.registerUserName})
          .then(() => {
                this.$vToastify.success({
                  mode: "prompt",
                  body: `Для подтверждения регистрации перейдите по ссылке в письме, отправленном на вашу почту ${this.registerEmail}`,
                  answers: {OK: true},
                  iconEnabled: false
                });
                this.authState = "login"
              }
          )
          .catch(reason => this.$vToastify.error(reason))
    },
    localRecover() {
      console.log("local recover")
      this.recover(this.recoverEmail)
          .finally(() => {
            this.$vToastify.success({
              mode: "prompt",
              body: `Если пользователь с этой почтой существует, то на неё придёт письмо с инструкциями для изменения пароля`,
              answers: {OK: true},
              iconEnabled: false
            });
            this.authState = "login"
          })
    },
    ...mapMutations({
      setAdminMode: 'navigation/setAdminMode'
    }),
    ...mapActions({
      login: 'api/login',
      register: 'api/register',
      recover: 'api/recover',
      getUser: 'api/getUserInfo'
    })
  },
  computed: {
    rules() {
      const rules = []
      {
        const rule =
            v => (v || '').length >= this.min ||
                `Минимальное количество символов: ${this.min}`

        rules.push(rule)
      }

      return rules
    },
    matchPassword() {
      return v => v === this.registerPassword || "Пароли не совпадают"
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
