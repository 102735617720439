<template>
  <v-select
      :items="items"
      :label="label"
      :rules="[v => !!v || 'Обязательное поле']"
      :value="internalValue"
      chips
      item-text="name"
      item-value="id"
      multiple
      required
      @change="changeHandle"
  ></v-select>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'multi-select',
  props: {
    value: {
      type: Array,
      required: true,
      default: function () { return [] }
    },
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeType: {
      type: String,
      required: true
    },
    placeId: {
      type: String,
      required: true
    },
    tagName: {
      type: String,
      required: true
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.newVal = val;
        this.$emit('input', val);
      }
    }
  },
  methods: {
    async save(){
      const newVal = this.newVal;
      if (newVal==null) return;

      const oldVal = this.internalValue
      console.log(newVal, oldVal);
      const added = newVal.filter(x => !oldVal.includes(x));

      if (added && added.length > 0) {

        console.log("Added:" + added)
        await this.addTagById({
          placeType: this.placeType,
          placeId: this.placeId,
          tagName: this.tagName,
          tags: added
        })
      }
      const deleted = oldVal.filter(x => !newVal.includes(x));
      if (deleted && deleted.length > 0) {
        //await wait(1000);
        console.log("Deleted:" + deleted)
        await this.deleteTagById({
          placeType: this.placeType,
          placeId: this.placeId,
          tagName: this.tagName,
          tags: deleted
        })
      }

      //await wait(1000);

      return Promise.resolve();
    },
    changeHandle(newVal) {
      this.newVal = newVal;
    },
    ...mapActions({
      addTagById: 'places/addTagById',
      deleteTagById: 'places/deleteTagById'
    })
  },
  data() {
    return {
      newVal: null
    }
  }
}
</script>
