<template>
  <div>
    <v-btn @click="startUpload">{{ btnTitle }}</v-btn>
    <v-file-input
        v-show="false"
        ref="uploader"
        v-model="fileModel"
        :accept="acceptExtensions"
        class="px-2"
        @change="showNameSelectionDialog"
    ></v-file-input>
    <v-dialog
        v-model="dialog" width="500">
      <v-card>
        <v-card-title>Выберите имя</v-card-title>
        <v-form v-model="valid">
          <v-card-text>
            <v-text-field
                v-model="selectedFileName"
                :rules="[rules.required, rules.counter]"
                counter
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="error"
                @click="dialog=false"
            >
              Отмена
            </v-btn>
            <v-spacer/>
            <v-btn
                :disabled="!valid"
                color="success"
                @click="upload"
            >
              Подтвердить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "FileUploader",
  props: {
    fileType: {
      type: String,
      required: true
    },
    filePath: {
      type: String,
      required: true
    },
    overrideFileName: {
      type: String,
      required: false,
      default: null
    },
    showNameEditor: {
      type: Boolean,
      required: false,
      default: false
    },
    acceptExtensions: {
      type: String,
      required: false,
      default: '.jpg'
    },
    btnTitle: {
      type: String,
      default: 'Загрузить'
    }
  },
  methods: {
    ...mapActions({
      uploadFile: 'files/uploadFile',
    }),
    showNameSelectionDialog(file) {
      console.log("SELECT FILE", file);
      if (file == null) return;
      this.file = file;
      this.fileModel = null;
      this.$refs.uploader.$refs.input.value = null;
      this.selectedFileName = this.overrideFileName ?? file.name;
      if (this.showNameEditor) {
        this.dialog = true;
      } else this.upload();
    },
    upload() {
      this.dialog = false;
      if (!this.file) return;
      console.log(this.file);
      const app = this;
      const reader = new FileReader();
      reader.onload = async function () {
        await app.uploadFile(
            {
              type: app.fileType,
              path: app.filePath,
              fileName: app.selectedFileName,
              formData: reader.result
            });

        app.refresh();
      }
      reader.readAsArrayBuffer(this.file);
    },
    startUpload() {
      this.$refs.uploader.$refs.input.click()
    },
    refresh() {
      this.$emit('refresh')
    }
  },
  data() {
    return {
      file: null,
      fileModel: null,
      selectedFileName: "",
      dialog: false,
      valid: null,
      rules: {
        required: value => !!value || 'Обязательное поле',
        counter: value => value.length <= 50 || 'Не больше 50 символов',
      },
    }
  }
}
</script>

<style scoped>

</style>
