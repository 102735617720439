import axios from "@/plugins/axios";

export const places = {
    namespaced: true,
    state: () => ({
        attractions: [],
        hotels: [],
        restaurants: [],
        routes: [],
        events: [],
        tags: {},
    }),
    getters: {
        attractions: state => state.attractions,
        hotels: state => state.hotels,
        restaurants: state => state.restaurants,
        routes: state => state.routes,
        events: state => state.events,
        hotelProps: state => state.hotelProps,
        restaurantProps: state => state.restaurantProps,
        tags: (state) => (path) => {
            return state.tags[path];
        }
    },
    mutations: {
        setPlaces(state, options) {
            console.log(options)
            state[`${options.placeType}s`] = options.places;
            console.log(state)
        },
        setCities(state, cities) {
            state.cities = cities.items;
        },
        setTags(state, {path, data}) {
            state.tags[path] = data.items;
        }
    },
    actions: {
        searchPlace(_, {placeType, query}) {
            return new Promise((resolve, reject) => {
                axios.post(`${placeType}/WithLinks/GetPaging?page=1&pageSize=1000&showInvisible=true`,
                    query)
                    .then(resp => {
                        resolve(resp.data)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadPlaceById(_, {placeType, id}) {
            return new Promise((resolve, reject) => {
                axios.get(`${placeType}/${id}/`)
                    .then(resp => {
                        resolve(resp.data)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadPlaces({commit}, placeType) {
            return new Promise((resolve, reject) => {
                axios.get(`${placeType}/WithLinks/GetPaging?page=1&pageSize=1000&showInvisible=true`)
                    .then(resp => {
                        //console.log(resp.data.items)
                        let newData = resp.data.items.map(x => {
                            const field = placeType.toLowerCase();
                            const core = {...x[field]};

                            delete x[field];

                            return {...x, ...core};
                        })

                        //console.log(newData)
                        commit('setPlaces', {placeType: placeType, places: newData})
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadCities({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`Cities?page=1&pageSize=1000`)
                    .then(resp => {
                        commit('setCities', resp.data)
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        changePlace(_, {placeType, place}) {
            return new Promise((resolve, reject) => {
                axios.put(`${placeType}`, place)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        createPlace(_, {placeType, place}) {
            return new Promise((resolve, reject) => {
                axios.post(`${placeType}`, place)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        deletePlace(_, {placeType, placeId}) {
            return new Promise((resolve, reject) => {
                axios.delete(`${placeType}/DeleteById?id=${placeId}`)
                    .then(resp => {
                        console.log(resp.data);
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadTags({commit}, path) {
            return new Promise((resolve, reject) => {
                axios.get(`${path}?page=1&pageSize=1000`)
                    .then(resp => {
                        commit('setTags', {path: path, data: resp.data});
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        deleteTagRaw(_, {parentName, childName, tagId}) {
            return new Promise((resolve, reject) => {
                axios.delete(`${parentName}${childName}/DeleteById?id=${tagId}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        addTagRaw(_, {parentName, childName, parentId, childId}) {
            return new Promise((resolve, reject) => {
                let data = {id: 0};
                data[`${parentName}Id`] = parentId;
                data[`${childName}Id`] = childId;

                axios.post(`${parentName}${childName}`, data)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadTagsRaw(_, {relationName, id, field}) {
            return new Promise((resolve, reject) => {
                axios.post(`${relationName}/GetPagingFiltered?page=1&pageSize=1000`,
                    {
                        "order": {
                            "propertyName": "id",
                            "isDes": false
                        },
                        "filter": {
                            "type": "Binary",
                            "unit1": {
                                "type": "Parameter",
                                "PropertyName": field
                            },
                            "unit2": {
                                "type": "Const",
                                "value": id
                            },
                            "operatorType": "Equals"
                        }
                    }
                )
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        createTag(_, {path, value}) {
            return new Promise((resolve, reject) => {
                axios.post(`${path}`, value)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        changeTag(_, {path, value}) {
            console.log(path, value)
            return new Promise((resolve, reject) => {
                axios.put(`${path}`, value)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        deleteTag(_, {path, id}) {
            return new Promise((resolve, reject) => {
                axios.delete(`${path}/DeleteById?id=${id}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        addTagById(_, {placeType, placeId, tagName, tags}) {
            console.log(tags, tagName)
            return new Promise((resolve, reject) => {
                const parameters = tags.map(x => `${tagName}Ids=${x}`).join('&');
                console.log("parameters", parameters);
                axios.post(`${placeType}/${placeId}/Add${tagName}ById?${parameters}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        deleteTagById(_, {placeType, placeId, tagName, tags}) {
            return new Promise((resolve, reject) => {
                console.log("TAGS", tags);
                const parameters = tags.map(x => `${tagName}Ids=${x}`).join('&');
                console.log("parameters", parameters);
                axios.delete(`${placeType}/${placeId}/Delete${tagName}ById?${parameters}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getRouteAttractions({dispatch}, placeId) {
            return dispatch('loadTagsRaw', {relationName: 'RouteAttraction', id: placeId, field: 'routeId'});
        },
        updateRouteOrder(_, {attractionId, routeId, order}) {
            return new Promise((resolve, reject) => {
                axios.put(`RouteAttraction/UpdateOrder?attractionId=${attractionId}&routeId=${routeId}&order=${order}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        recalculateRoute(_, routeId) {
            return new Promise((resolve, reject) => {
                axios.put(`Route/RecalculateDistance?routeId=${routeId}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        setVisible(_, {placeType, placeId, mode}) {
            return new Promise((resolve, reject) => {
                axios.post(`${placeType}/${placeId}/SetVisible?visible=${mode}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        }
    }
}
