import axios from "@/plugins/axios";

export const stats ={
    namespaced: true,
    state: () => ({
        todayStats: {},
        stats: [],
        online: 0,
        requestDelay: 0,
        aiLevel: 0
    }),
    getters: {
        stats: state => state.stats,
        dau: state => state.todayStats.daily_online_users,
        mau: state => state.todayStats.monthly_online_users,
        online: state => state.online,
        requestDelay: state => state.requestDelay,
        aiLevel: state => state.aiLevel
    },
    mutations: {
        setStats(state, stats) {
            state.stats = stats;
        },
        setTodayStats(state, stats) {
            state.todayStats = stats;
        },
        setOnline(state, online) {
            state.online = online
        },
        setRequest(state, req) {
            state.requestDelay = req;
        },
        setAiLevel(state, level){
            state.aiLevel = level
        }
    },
    actions: {
        getStats({commit}, request) {
            return new Promise((resolve, reject) => {
                axios.post('admin/stats/search', request)
                    .then(resp => {
                        console.log(resp.data)
                        console.log(JSON.stringify(resp.data))
                        commit('setStats', resp.data.elements)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getTodayStats({commit}) {
            return new Promise((resolve, reject) => {
                axios.post('admin/stats/getTodayStats')
                    .then(resp => {
                        console.log(resp.data)
                        console.log(JSON.stringify(resp.data))
                        commit('setTodayStats', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getOnline({commit}) {
            return new Promise((resolve, reject) => {
                axios.post('admin/stats/getCurrentOnline')
                    .then(resp => {
                        console.log(resp.data)
                        commit('setOnline', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getRequestDelay({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('common/getRecommendedRequestsDelay')
                    .then(resp => {
                        console.log(resp.data)
                        commit('setRequest', resp.data.data.delay_ms + "ms")
                        //commit('setUsers', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getAiLevel({commit}) {
            return new Promise((resolve, reject) => {
                axios.post('games/getAILevel')
                    .then(resp => {
                        console.log(resp.data)
                        commit('setAiLevel', resp.data.data.accuracy)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        setAiLevel({dispatch}, newLevel){
            return new Promise((resolve, reject) => {
                axios.post('admin/games/setAILevel', {ai_hunt_accuracy: newLevel})
                    .then(resp => {
                        console.log(resp.data)
                        dispatch('getAiLevel')
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        }
    }
}
