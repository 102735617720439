import axios from "@/plugins/axios";

export const reviews = {
    namespaced: true,
    state: () => ({

    }),
    actions: {
        getReviews(_, {page, pageSize, options}) {
            return new Promise((resolve, reject) => {
                axios.post(`Review/GetPaging?page=${page}&pageSize=${pageSize}`, options)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        publish(_, review){
            return new Promise((resolve, reject) => {
                axios.put(`Review/UpdateTo${review.placeType}`, review)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        delete (_, id){
            return new Promise((resolve, reject) => {
                axios.delete(`Review?id=${id}`)
                    .then(resp => {
                        console.log(resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        }
    }
}
