<template>
  <v-img :height="height" :src="image" contain></v-img>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "FileStorageImage",
  props: {
    height: {
      type: Number,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      image: null
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.image = null
      if (!this.path) {
        this.displayNoImage();
      } else
        this.fetchFiles({type: this.type, path: this.path})
            .then(data => this.displayImage(data))
            .catch(() => this.displayNoImage())
    },
    displayImage(data) {
      const app = this;
      console.log(data.data);
      if (data.data.some(x => x.fileName === this.fileName)) {
        this.getFile({type: this.type, path: this.path, fileName: this.fileName})
            .then(file => {
              const bytes = new Uint8Array(file.data);
              const binary = bytes.reduce((data, b) => data + String.fromCharCode(b), '');
              app.image = "data:image/jpeg;base64," + btoa(binary);
              this.$emit('loaded', true);
            })
      } else {
        this.displayNoImage()
      }
    },
    displayNoImage() {
      this.$emit('loaded', false);
      console.log("No image found");
      this.image = "./images/no-image.jpg";
    },
    ...mapActions({
      fetchFiles: 'files/fetchFiles',
      getFile: 'files/getFile'
    }),
  }
}
</script>

<style scoped>

</style>
