<template>
  <v-dialog
      v-model="innerDialog"
      width="800"
  >
    <v-skeleton-loader
        v-if="!loaded"
        max-width="800"
        type="heading, table-cell@10"></v-skeleton-loader>
    <v-card
        v-else
        max-width="800">
      <v-card-title>
        ID: {{ place.id }}
      </v-card-title>
      <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-card v-if="!create">
            <v-card-title>Миниатюра</v-card-title>
            <image-upload
                v-if="!create"
                :path="place.path"
                file-name="x/1.jpg"
                type="Picture"
                @changed="changed=true"
            ></image-upload>
            <v-card-title>Основная обложка</v-card-title>
            <image-upload
                v-if="!create"
                :path="place.path"
                file-name="2x/1.jpg"
                type="Picture"
                @changed="changed=true"
            ></image-upload>
            <image-list
                :except="['1.jpg']"
                :file-path="place.path + '/2x'"
            ></image-list>
          </v-card>
          <audio-list
              v-if="!create && placeType==='attraction'"
              :filePath="place.path"
              class="my-2"
          ></audio-list>
          <v-text-field ref="name" :rules="[rules.req]" :value="place.name" label="Имя" required></v-text-field>
          <v-text-field ref="path" :rules="[rules.req]" :value="place.path" hint="Английские буквы и подчеркивания"
                        label="Путь"
                        required></v-text-field>
          <v-text-field ref="weight" :rules="[rules.req]"
                        :value="place.weight"
                        hint="Чем выше тем приоритетнее в списке"
                        label="Вес"
          ></v-text-field>
          <mono-select ref="districtId" :items="tags('District')"
                       :value="place.districtId"
                       label="Район"/>
          <mono-select ref="cityId" :items="tags('Cities')"
                       :value="place.cityId"
                       label="Город"/>
          <!--          <v-text-field ref="preview" :rules="[rules.req]" :value="place.preview" label="Превью"
                                  required></v-text-field>-->
          <v-textarea ref="description" :rules="[rules.req]" :value="place.description" label="Описание"
                      required></v-textarea>
          <div v-if="placeType==='hotel' || placeType==='restaurant' || placeType==='attraction' ">
            <v-menu
                ref="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.req]"
                    label="Дата постройки" prepend-icon="mdi-calendar"
                    readonly
                    required
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>

          <div v-if="placeType!=='route'">
            <v-text-field ref="address" :rules="[rules.req]" :value="place.address" label="Адрес"
                          required></v-text-field>
            <v-text-field ref="latitude" :rules="[rules.req]" :value="place.latitude" label="Широта"
                          required type="number"></v-text-field>
            <v-text-field ref="longitude" :rules="[rules.req]" :value="place.longitude" label="Долгота"
                          required type="number"></v-text-field>
            <v-text-field ref="discount" :rules="[rules.req]" :value="place.discount" label="Скидка"
                          required type="number"></v-text-field>
          </div>

          <div v-if="placeType==='route'">
            <v-checkbox ref="animals" :value="place.animals" label="С животными"></v-checkbox>
            <v-card>
              <v-card-title>Расстояние и время</v-card-title>

              <v-card-text>

                <v-text-field ref="length" :rules="[rules.req]" :value="place.length" label="Длина (км)"
                              required type="number"></v-text-field>
                <v-text-field ref="time" :rules="[rules.req]" :value="place.time" label="Время прохода (мин)"
                              required type="number">
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="recalculate">Пересчитать</v-btn>
              </v-card-actions>
            </v-card>

            <multi-select
                v-if="!create"
                ref="ageType"
                :items="tags('ageType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.ageTypeIds"
                label="Возраст"
                tag-name="ageType"
            ></multi-select>
            <multi-select
                v-if="!create"
                ref="peopleType"
                :items="tags('peopleType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.peopleTypeIds"
                label="Тип компании"
                tag-name="peopleType"
            ></multi-select>
            <multi-select
                v-if="!create"
                ref="subjectType"
                :items="tags('subjectType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.subjectTypeIds"
                label="Тип тематики"
                tag-name="subjectType"
            ></multi-select>
            <multi-select
                v-if="!create"
                ref="subjectName"
                :items="tags('subjectName')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.subjectNameIds"
                label="Тематика"
                tag-name="subjectName"
            ></multi-select>
            <attractions-select
                v-if="!create"
                ref="attraction"
                :place-id="place.id != null ? place.id.toString() : '0'"
                label="Достопримечательности"
            ></attractions-select>
          </div>
          <div v-if="placeType==='restaurant'">
            <mono-select ref="cateringType" :items="tags('cateringType')"
                         :value="place.cateringTypeId"
                         label="Тип ресторана"/>
            <multi-select
                v-if="!create"
                ref="cuisineTypes"
                :items="tags('cuisineType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.cuisineTypeIds"
                label="Кухня"
                tag-name="cuisineType"
            />
            <multi-select
                v-if="!create"
                ref="denyTypes"
                :items="tags('denyType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.denyTypeIds"
                label="Особенности"
                tag-name="denyType"
            />
          </div>
          <div v-if="placeType==='hotel'">
            <mono-select ref="housingTypeId" :items="tags('housingType')"
                         :value="place.housingTypeId"
                         label="Тип отеля"/>
            <multi-select
                v-if="!create"
                ref="equipmentTypes"
                :items="tags('equipmentType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.equipmentTypes"
                label="Снаряжение"
                tag-name="equipmentType"
            />
            <multi-select
                v-if="!create"
                ref="serviceTypes"
                :items="tags('serviceType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.serviceTypes"
                label="Сервис"
                tag-name="serviceType"
            />
          </div>


          <div v-if="placeType==='event'">
            <v-menu
                ref="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="date"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="eventStartDate"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.req]"
                    label="Дата начала" prepend-icon="mdi-calendar"
                    readonly
                    required
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="eventStartDate"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="menuStartDate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="$refs.menuStartDate.save(eventStartDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
                ref="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="date"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="eventEndDate"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.req]"
                    label="Дата окончания" prepend-icon="mdi-calendar"
                    readonly
                    required
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="eventEndDate"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="menuEndDate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="$refs.menuEndDate.save(eventEndDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>

          <div v-if="placeType==='hotel' || placeType==='restaurant' || placeType==='event' ">
            <v-text-field
                ref="url"
                :value="place.url"
                label="Ссылка"
            ></v-text-field>
            <v-text-field
                ref="phone"
                :value="place.phone"
                label="Телефон"
            ></v-text-field>
          </div>

          <div v-if="placeType==='attraction'">
            <v-text-field ref="duration" :rules="[rules.req]" :value="place.duration" label="Длительность" required
                          type="number"></v-text-field>
            <multi-select
                v-if="!create"
                ref="subject"
                :items="tags('Subject')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.subjectIds"
                label="Тематики"
                tag-name="Subject"
            ></multi-select>
            <multi-select
                v-if="!create"
                ref="PlaceType"
                :items="tags('placeType')"
                :place-id="place.id != null ? place.id.toString() : '0'"
                :place-type="placeType"
                :value="place.placeTypeIds"
                label="Тип места"
                tag-name="placeType"
            ></multi-select>
          </div>
          <div v-if="create">Дополнительные параметры доступны после сохранения</div>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn
              v-if="!create"
              color="error"
              outlined
              @click="deletePlace({placeType: placeType, placeId: place.id}) + refresh() +close() "
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              @click="close"
          >
            Закрыть
          </v-btn>
          <v-btn
              :disabled="!valid"
              color="success"
              outlined
              @click="performAction"
          >
            {{ create ? 'Создать' : 'Сохранить' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MonoSelect from "@/components/fields/MonoSelect";
import MultiSelect from "@/components/fields/MultiSelect";
import ImageUpload from "@/components/fields/ImageUpload";
import AttractionsSelect from "@/components/fields/AttractionsSelect";
import AudioList from "@/components/widgets/AudioList";
import ImageList from "@/components/widgets/ImageList";

export default {
  name: "PlaceCard",
  components: {ImageList, AudioList, AttractionsSelect, ImageUpload, MonoSelect, MultiSelect},
  props: {
    place: {type: Object, required: true},
    placeType: {type: String, required: true},
    create: {type: Boolean, required: true},
    value: {type: Boolean, required: true}
  },
  data() {
    return {
      loaded: true,
      commonFields: ['name', 'preview', 'description', 'path', 'weight'],
      specificMonoFields: {
        attraction: ['duration', 'address', 'latitude', 'longitude', 'districtId', 'cityId', 'discount'],
        hotel: ['address', 'latitude', 'longitude', 'cityId', 'districtId', 'discount', 'housingTypeId', 'url', 'phone'],
        restaurant: ['address', 'latitude', 'longitude', 'cityId', 'districtId', 'discount', 'cateringType', 'url', 'phone'],
        route: ['animals', 'time', 'length', 'districtId', 'cityId'],
        event: ['address', 'latitude', 'longitude', 'cityId', 'districtId', 'discount', 'url', 'phone']
      },
      specificMultiFields: {
        attraction: ['subject', 'PlaceType'],
        hotel: ['equipmentTypes', 'serviceTypes'],
        restaurant: ['cuisineTypes', 'denyTypes'],
        route: ['peopleType', 'subjectType', 'subjectName', 'attraction', 'ageType'],
        event: []
      },
      extraFields: {
        attraction: {preview: ""},
        hotel: {preview: ""},
        restaurant: {preview: ""},
        route: {preview: ""},
        event: {preview: ""},
      },
      date: null,
      valid: null,
      rules: {
        req: v => (!!v || v === 0) || "Обязательное поле"
      },
      eventStartDate: null,
      eventEndDate: null,
      changed: false
    }
  },
  methods: {
    ...mapActions({
      deletePlace: 'places/deletePlace',
      changePlace: 'places/changePlace',
      createPlace: 'places/createPlace',
      loadTags: 'places/loadTags',
      loadPlaces: 'places/loadPlaces',
      recalculateRoute: 'places/recalculateRoute'
    }),
    close() {
      this.innerDialog = false;
    },
    refresh() {
      setTimeout(() => this.$emit('refresh'), 500)
    },
    refreshAndOpen(id) {
      setTimeout(() => {
        this.$emit('refresh');
        setTimeout(() => {
          this.$emit('open', id)
        }, 500)
      }, 500)
    },
    getCommonFields() {
      let o = {}
      o.id = this.place.id;
      o.buildDate = this.date;
      if (this.placeType === 'event') {
        o.startDate = this.eventStartDate;
        o.endDate = this.eventEndDate;
      }
      for (const field of this.commonFields) {
        const el = this.$refs[field]
        if (el != null)
          o[field] = el.internalValue?.toString().trim();
      }
      return o;
    },
    getSpecificFields() {
      let o = {}
      for (const field of this.specificMonoFields[this.placeType]) {
        const el = this.$refs[field]
        if (el != null)
          o[field] = el.internalValue;
      }
      return o;
    },
    async saveMultiFields() {
      let o = {}
      for (const field of this.specificMultiFields[this.placeType]) {
        const el = this.$refs[field]
        if (el != null)
          await el.save();
      }
      return o;
    },
    async saveAll(o, s) {
      await this.saveMultiFields();
      let result = {...o, ...this.extraFields[this.placeType], ...s}
      console.log(result)
      if (this.create) {
        this.createPlace({placeType: this.placeType, place: result})
            .then((result) => {
              console.log("PLACE CREATED", result)
              this.refreshAndOpen(result.data.id);
              this.close();
              this.$vToastify.success("Создано");
            })
      } else
        this.changePlace({placeType: this.placeType, place: result})
            .then(() => {
              this.refresh();
              this.close();
              this.$vToastify.success("Сохранено");
            })
    },
    performAction() {
      let o = this.getCommonFields();
      let s = this.getSpecificFields();
      this.saveAll(o, s);
    },
    async recalculate() {
      await this.recalculateRoute(this.place.id);
      this.refreshAndOpen(this.place.id);
      this.close();
      //this.refresh();
    }
  },
  computed: {
    ...mapGetters({
      tags: 'places/tags'
    }),
    innerDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    innerDialog(newVal) {
      this.$refs.form?.validate();
      this.date = this.place?.buildDate?.substring(0, 10)
      if (this.placeType === 'event') {
        this.eventStartDate = this.place?.startDate?.substring(0, 10);
        this.eventEndDate = this.place?.endDate?.substring(0, 10);
      }
      if (!newVal && this.changed) this.refresh()
    }
  },
  mounted() {
    this.$refs.form?.validate();
  }
}
</script>

