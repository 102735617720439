<template>
  <div>
    <v-card elevation="0">
      <!--      <v-img  :src="image" contain height="200"></v-img>-->

      <file-storage-image
          :key="imgKey"
          :file-name="fileName"
          :height="300"
          :path="path"
          :type="type"
          @loaded="imageLoaded"
      ></file-storage-image>
      <v-card-actions>
        <v-btn @click="startUploading">Загрузить</v-btn>
        <v-file-input
            v-show="false"
            ref="uploader"
            v-model="file"
            accept=".jpg, .png"
            class="px-2"
            @change="upload"
        ></v-file-input>
        <v-spacer/>
        <v-btn :disabled="!image"
               color="warning"
               @click="deleteAllCovers">Удалить изображение
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import FileStorageImage from "@/components/widgets/FileStorageImage";

export default {
  name: "ImageUpload",
  components: {FileStorageImage},
  props: {
    path: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    additionalFiles: {
      type: Array,
      required: false,
      default: () => []
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      image: false,
      file: null,
      imgKey: "asd"
    }
  },
  methods: {
    getAllFiles() {
      return [this.fileName, ...this.additionalFiles];
    },
    refresh() {
      console.log('ALL FILES', this.getAllFiles());
      this.imgKey = Date.now();
    },
    async upload() {
      if (!this.file) return;
      console.log(this.file);
      const app = this;
      const reader = new FileReader();
      reader.onload = async function () {
        const fileNames = app.getAllFiles();
        for (const f of fileNames) {
          console.log("DELETING", f);
          await app.uploadFile({type: app.type, path: app.path, fileName: f, formData: reader.result});
        }

        app.$emit('changed');
        app.refresh();
      }
      reader.readAsArrayBuffer(this.file);

    },
    async deleteAllCovers() {
      const fileNames = this.getAllFiles();
      for (const f of fileNames) {
        console.log("DELETING", f);
        await this.deleteFile({type: this.type, path: this.path, fileName: f});
      }
      this.$emit('changed');
      this.refresh();
    },
    ...mapActions({
      fetchFiles: 'files/fetchFiles',
      getFile: 'files/getFile',
      uploadFile: 'files/uploadFile',
      deleteFile: 'files/deleteFile',
    }),
    imageLoaded(mode) {
      this.image = mode;
    },
    startUploading() {
      this.$refs.uploader.$refs.input.click()
    }
  },
  mounted() {
    this.refresh()
  },
  watch: {
    path() {
      this.refresh();
    }
  }
}
</script>

<style scoped>

</style>
