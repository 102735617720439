<template>

  <v-container fluid>
    <v-row>
      <v-col
          cols="12"
      >
        <v-card>
          <v-card-title>Профиль пользователя {{ user.userName }}</v-card-title>
          <v-card-text>
            <v-list-item two-line v-for="(caption, name) in captions" :key="name">
              <v-list-item-content>
                <v-list-item-title>{{caption}}</v-list-item-title>
                <v-list-item-subtitle>{{user[name]}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Profile",
  data() {
    return{
      captions: {
        "email": "Email",
        "emailConfirmed": "Подтверждено",
        "registrationDate": "Дата регистрации",
        "platform" :"Платформа",
        "isBanned": "Забанен",
        "roleName": "Роль"
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'api/user'
    })
  }
}
</script>

<style scoped>

</style>
