<template>
  <v-select
      :items="items"
      :label="label"
      :rules="[v => !!v || 'Обязательное поле']"
      :value="internalValue"
      chips
      item-text="name"
      item-value="id"
      multiple
      required
      @change="changeHandle"
  ></v-select>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'multi-select-raw',
  props: {
    value: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    rawValue: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    relationParentName: {
      type: String,
      required: true
    },
    relationChildName: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      required: true
    },
    tagName: {
      type: String,
      required: true
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.newVal = val;
        this.$emit('input', val);
      }
    }
  },
  methods: {
    async save() {
      const newVal = this.newVal;
      if (newVal == null) return;

      const oldVal = this.internalValue
      console.log(newVal, oldVal);
      const added = newVal.filter(x => !oldVal.includes(x));

      console.log("ADDED", added)

      if (added && added.length > 0) {

        for (const addedTag of added) {
          await this.addTagRaw({
            parentName: this.relationParentName,
            childName: this.relationChildName,
            parentId: this.parentId,
            childId: addedTag
          })
        }
      }
      const deleted = oldVal.filter(x => !newVal.includes(x));
      if (deleted && deleted.length > 0) {
        //await wait(1000);
        console.log("Deleted:" + deleted)

        for (const deletedTag of deleted) {
          console.log("Deleted tag", deletedTag, "All deleted", this.rawValue);
          const fieldName = this.relationChildName+ 'Id';
          var raw = this.rawValue.find(x => x[fieldName] === deletedTag);
          console.log(raw, fieldName);
          if (raw) {
            await this.deleteTagRaw({
              parentName: this.relationParentName,
              childName: this.relationChildName,
              tagId: raw.id
            })
          }

        }
      }

      //await wait(1000);

      return Promise.resolve();
    },
    changeHandle(newVal) {
      this.newVal = newVal;
    },
    ...mapActions({
      addTagRaw: 'places/addTagRaw',
      deleteTagRaw: 'places/deleteTagRaw',
      addTagById: 'places/addTagById',
      deleteTagById: 'places/deleteTagById'
    })
  },
  data() {
    return {
      newVal: null
    }
  }
}
</script>
