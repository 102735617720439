<template>
  <v-app>
    <div v-if="$route.path==='/confirm'">
      <confirm></confirm>
    </div>
    <div v-else-if="$route.path==='/recover'">
      <recover></recover>
    </div>
    <div v-else>
      <div v-if="!init"></div>
      <div v-else>
        <Auth v-if="isLoggedIn === false"></Auth>
        <MainContext v-else/>
        <v-overlay
            :value="isLoading"
            absolute
            z-index="999"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
import Auth from '@/components/Auth';
import MainContext from "@/components/MainContext";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Confirm from "@/pages/Confirm";
import recover from "@/pages/Recover";

export default {
  name: "Index",
  data() {
    return {
      init: false
    }
  }
  ,
  components: {
    recover,
    Confirm,
    MainContext,
    Auth,
  },
  methods: {
    ...mapActions({
      initToken: "api/initToken",
      getUser: "api/getUserInfo"
    }),
    ...mapMutations({
      setAdminMode: 'navigation/setAdminMode'
    })
  },
  computed: {
    ...mapState({
      waitingForResponse: state => state.api.waitingForResponse
    }),
    ...mapGetters({
      isLoggedIn: 'api/isLoggedIn',
      isLoading: 'api/isLoading'
    }),
  },
  mounted() {
    this.initToken().finally(() => this.getUser()
        .then(u => {
          const admin = u.data.roleName === 'admin';
          console.log("USER is admin", admin);
          this.setAdminMode(admin);
        })
        .finally(() => this.init = true));
  }

};
</script>

<style>
.titleWrapFix {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  word-break: normal;
  hyphens: auto;
}
</style>
