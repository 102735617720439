<template>
  <div>
    <v-card-title>Дополнительные изображения</v-card-title>
    <v-list>
      <v-list-item
          v-for="(file, index) in files"
          :key="index"
      >
        <file-storage-image
            :file-name="file.fileName"
            :height="100"
            :path="filePath"
            style="width: 100px"
            type="Picture"
        ></file-storage-image>
        <file-storage-file
            :file-name="file.fileName"
            :path="filePath"
            type="Picture"
            @refresh="refresh"
        ></file-storage-file>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer></v-spacer>
      <file-uploader
          :file-path="filePath"
          :show-name-editor=true
          accept-extensions=".jpg"
          file-type="Picture"
          @refresh="refresh"
      ></file-uploader>
    </v-card-actions>
  </div>
</template>

<script>
import FileStorageFile from "@/components/widgets/FileStorageFile";
import {mapActions} from "vuex";
import FileUploader from "@/components/fields/FileUploader";
import FileStorageImage from "@/components/widgets/FileStorageImage";

export default {
  name: "ImageList",
  components: {FileStorageImage, FileUploader, FileStorageFile},
  props: {
    filePath: {
      type: String,
      required: true
    },
    except: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      files: []
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      fetchFiles: 'files/fetchFiles'
    }),
    async refresh() {
      try {
        let files = await this.fetchFiles({type: 'Picture', path: this.filePath});
        console.log(this.except)
        this.files = files.data.filter(x => !this.except.includes(x.fileName));
      } catch (e) {
        this.files = [];
      }
    },
  },
  watch: {
    filePath() {
      this.refresh();
    }
  }
}
</script>

<style scoped>

</style>
