import VueRouter from "vue-router";
//import Admin from "@/pages/Admin";
import Users from "@/pages/Users";
import Profile from "@/pages/Profile";
import Places from "@/pages/Places";
import Categories from "@/pages/Categories";
import Reviews from "@/pages/Reviews";
//import Codes from "@/pages/Codes";

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Profile,
            name: "Профиль",
            meta: {
                icon: "mdi-home",
                admin: false
            }
        },
        {
            path: '/users',
            component: Users,
            name: "Пользователи",
            meta: {
                icon: "mdi-account-group",
                admin: true
            }
        },
        {
            path: '/places',
            component: Places,
            name: "Места",
            meta: {
                icon: "mdi-map-search",
                admin: true
            }
        },
        {
            path: '/categories',
            component: Categories,
            name: "Категории",
            meta: {
                icon: "mdi-tag-multiple",
                admin: true
            }
        },
        {
            path: '/reviews',
            component: Reviews,
            name: "Отзывы",
            meta: {
                icon: "mdi-message-draw",
                admin: true
            }
        }
    ]
})
