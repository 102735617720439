<template>
  <v-card>
    <v-card-actions>
      <v-btn @click="refresh">Update</v-btn>
      <v-btn
          v-show="selected!=null && selected.length > 0"
          color="error"
          @click="deleteItems">Delete
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-data-table
        v-model="selected"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, 100, 500, 1000]}"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
        class="elevation-1"
        show-select
    >
      <template v-slot:item.placeId="{ item }">
        {{ placeNames[item.placeId] }}
      </template>
      <template v-slot:item.userId="{ item }">
        {{ userById(item.userId).userName }}
      </template>
      <template v-slot:item.isVisible="{ item }">
        <div v-if="item.isVisible">
          <v-btn
              class="mx-2"
              icon
              outlined
              @click="publishReview(item, false)"
          >
            <v-icon>mdi-eye-off</v-icon>
          </v-btn>
          <v-chip class="green lighten2">Опубликован</v-chip>
        </div>
        <div v-else>
          <v-btn
              class="mx-2"
              icon
              outlined
              @click="publishReview(item, true)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-chip class="grey darken-1">Не опубликован</v-chip>
        </div>
      </template>

    </v-data-table>
    <v-row justify="center">
      <v-overlay
          :value="overlay"
          :z-index="zIndex"
      >
        <v-progress-circular
            :rotate="360"
            :size="100"
            :value="(loadProgress/loadTotal)*100"
            :width="15"
            color="teal"
        >
          {{ loadProgress }} / {{ loadTotal }}
        </v-progress-circular>
      </v-overlay>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReviewsTable",
  data() {
    return {
      loading: true,
      options: {
        sortBy: ['isVisible'],
        sortDesc: [false],
      },
      items: [],
      total: 0,
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Created', value: 'createDate'},
        {text: 'Place', value: 'placeId'},
        {text: 'PlaceType', value: 'placeType'},
        {text: 'User', value: 'userId'},
        {text: 'Text', value: 'text'},
        {text: 'Rate', value: 'rate'},
        {text: 'Visible', value: 'isVisible'},
      ],
      selected: [],
      zIndex: 0,
      overlay: false,
      loadProgress: 0,
      loadTotal: 0,
      placeNames: {},
      userName: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.refresh()
      },
      deep: true,
    },
  },
  mounted() {
    //this.fetchCodes()
  },
  computed: {
    ...mapGetters({
      users: "users/users",
      userById: 'users/userById'
    })
  },
  methods: {
    ...mapActions({
      getReviews: 'reviews/getReviews',
      loadPlaceById: 'places/loadPlaceById',
      publish: 'reviews/publish',
      deleteReview: 'reviews/delete'
    }),
    refresh() {
      this.loading = true

      console.log("OPTIONS", this.options)

      const options = this.options.sortBy.length > 0 ? {
        order: {
          propertyName: this.options.sortBy[0],
          isDes: this.options.sortDesc[0]
        }
      } : {}

      this.getReviews({
        page: this.options.page,
        pageSize: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        options: options
      }).then(this.updateCodes)
    },
    async updateCodes(result) {
      for (const i of result.data.items) {
        console.log(i)
        this.placeNames[i.placeId] = await this.loadPlaceName(i.placeType, i.placeId)
      }

      this.items = result.data.items;
      this.total = result.data.total
      this.selected = []
      this.loading = false;
      console.log(result.data)
    },
    async deleteItems() {
      this.overlay = true;
      let ids = this.selected.map(x => x.id);

      this.startLoading(ids.length)

      let part = 0;

      for (const id of ids) {
        await this.deleteReview(id);
        this.changeProgress(++part);
      }

      this.stopLoading();
      this.refresh();
    },
    startLoading(total) {
      this.loadTotal = total;
      this.overlay = true;
      this.loadProgress = 0;
    },
    changeProgress(number) {
      this.loadProgress = number;
    },
    stopLoading() {
      this.overlay = false;
    },
    async loadPlaceName(placeType, placeId) {
      try {
        const place = await this.loadPlaceById({placeType: placeType, id: placeId});
        return place.name;
      } catch (e) {
        return "DELETED PLACE";
      }
    },
    async publishReview(item, isVisible) {
      item.isVisible = isVisible;

      await this.publish(item)
      this.refresh();
    }
  }
}
</script>

<style scoped>

</style>
